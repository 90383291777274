import { TextField, Autocomplete } from '@mui/material';
import { BankDetailsDto } from '@sr/dto';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import { SyntheticEvent, useCallback, useState } from 'react';
import { IRemoteCollection } from '../../../utils/remote-collection.hook';

export const BankDetailsPicker = (props: TextFieldProps & { legalEntityId?: number, collection: IRemoteCollection<BankDetailsDto> }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
    disabled,
    legalEntityId,
    collection,
  } = props;

  const [open, setOpen] = useState(false);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: BankDetailsDto | null) => {
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name]
  );

  const loading = open && collection?.items.length === 0 && collection?.isLoading;
  if (loading) {
    return null;
  }

  const selectedEntity = collection?.items.find(item => item.id === value) ?? null;

  const {
    legalEntityId: _,
    ...textFieldProps } = props;

  return (
    <Autocomplete
      value={selectedEntity}
      autoComplete={false}
      options={collection?.items || []}
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.bankName}, ${option.bik}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={legalEntityId && isNaN(legalEntityId) ? 'Укажите юрлицо' : 'Ничего не найдено'}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(textFieldProps)}
          {...params}
          name={name}
          label={label}
          placeholder="Начните набирать..." />
      }
    />);
};


