import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import isFuture from 'date-fns/isFuture';
import { useEffect, useState } from 'react';
import { fetchCompanyPersons } from 'shared/api/company-persons';
import { CreateEventDto, IPerson, EventTaskType } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { formatPerson } from 'utils/person-format-helpers';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { useStateSelector } from 'shared/store';
import { useAddEvent } from 'features/events/events-actions/event-actions.hook';

const types: Omit<{ [x in EventTaskType]: string }, 'task'> =
{
  meeting: 'Встреча',
  call_in: 'Входящий звонок',
  call_out: 'Исходящий звонок',
  email_in: 'Входящий емейл',
  email_out: 'Исходящий емейл'
};

export type FormData = {
  contactPersonId: number | '',
  content: string,
  type: EventTaskType | '',
  dueDate: Date | null;
};

type ValidationData = Partial<{ [x in keyof FormData]: string }>;


const initialValues: FormData = {
  contactPersonId: '',
  content: '',
  type: '',
  dueDate: null
};

type DialogProps = {
  open: boolean;
  companyId: number;
  onClose: () => void;
};

const AddEventDialog = (props: DialogProps) => {
  const {
    open,
    onClose,
    companyId } = props;

  const user = useStateSelector(store => store.auth.user);

  const [personList, setPersonList] = useState<IPerson[]>([]);
  const addEvent = useAddEvent(companyId);
  const { showError, showSuccess } = useSnack();

  useEffect(() => {
    if (open) {
      fetchCompanyPersons(companyId)
        .then(result => {
          setPersonList(result.map(x => x.person));
        })
        .catch(error => showError(`Ошибка получения контактных лиц компании: ${error.message}`));
    }
  }, [companyId, open]);

  const validate = (values: FormData): ValidationData => {
    let validationResult: ValidationData = {};

    if (!values.type)
      validationResult.type = 'Не выбран тип дела';

    if (!values.content)
      validationResult.content = 'Обязательное поле';

    if (!values.contactPersonId)
      validationResult.contactPersonId = 'Не указано контактное лицо';

    if (!values.dueDate)
      validationResult.dueDate = 'Не указана дата контакта';
    else if (!isFuture(values.dueDate))
      validationResult.dueDate = 'Дата контакта не может быть раньше текущей';

    return validationResult;
  };

  const handleSubmit = async (values: FormData) => {
    if (user === null) {
      throw new Error('Please login before adding events');
    }
    if (values.type==='' || values.contactPersonId ==='' || values.dueDate === null) {
      throw new Error('Forms is invalid');
    }
    const newEvent: CreateEventDto = {
      taskType: values.type,
      content: values.content,
      contactPersonId: values.contactPersonId,
      dueDate: values.dueDate
    };
    await addEvent(newEvent)
      .then(()=>showSuccess('Событие успешно добавлено'))
      .catch((e)=>{ showError(`Ошибка добавления события: ${e.message}`) })
      .finally(()=>{ onClose() });
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Новое событие</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm, isSubmitting }: any) => (
            <Form>
              <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                <Field
                  name='dueDate'
                  component={DatePickerField}
                  label="Дата следующего контакта"
                />
                <Field
                  label='Тип'
                  component={TextField}
                  select
                  name='type'
                >
                  {Object.entries(types).map(([type, label]) => (
                    <MenuItem
                      key={type}
                      value={type}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  name='contactPersonId'
                  label='Кому'
                  component={TextField}
                  select
                >
                  {personList.map(p => (
                    <MenuItem
                      key={p.id}
                      value={p.id}
                    >
                      {formatPerson(p)}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  name='content'
                  label='Текст'
                  multiline
                  rows={4}
                  component={TextField}
                />
              </Stack>
              <DialogActions>
                <Button variant="contained"
                  autoFocus
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'Подождите...' : 'Сохранить'}
                </Button>
                <Button variant="outlined"
                  onClick={onClose}
                  disabled={isSubmitting}>
                  Закрыть
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog >);
};

export default AddEventDialog;



