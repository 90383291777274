import { fetchPrepayment, prepaymentApi } from './prepayment.api';
import { CreatePrepaymentDto, PrepaymentDto, PurchaseDto, UpdatePrepaymentDto } from '@sr/dto';
import { useReadOnlyRemoteCollection } from '../../utils/remote-collection.hook';
import { useCallback } from 'react';
import { fetchInvoicePurchases } from '../../shared/api/purchases';

export const useAddPrepayment = () => {
  const [createPrepayment] = prepaymentApi.useCreatePrepaymentMutation();
  return async (dto: CreatePrepaymentDto) => {
    return createPrepayment(dto).unwrap();
  };
};

export const useUpdatePrepayment = () => {
  const [updatePrepayment] = prepaymentApi.useUpdatePrepaymentMutation();
  return async (dto: UpdatePrepaymentDto) => {
    return updatePrepayment(dto).unwrap();
  };
};

export const useDeletePrepayment = () => {
  const [callDelete] = prepaymentApi.useDeletePrepaymentMutation();

  return async (salaryEntryId: number) => {
    return callDelete(salaryEntryId).unwrap();
  };
};
