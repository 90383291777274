export enum CompanyPermissions {
  ACCESS_ANY = 'company.view-any',
  EDIT_METADATA = 'company.edit',
  MERGE = 'company.merge',
  LINK_MANAGER = 'company.manage-links',
}

// for future permissions implementations, eg. invoice permissions etc...
enum OtherPermissions {}

export type PermissionScope = CompanyPermissions | OtherPermissions;

export type AuthorizedUser = {
  id: number;
  firstName: string;
  secondName: string;
  middleName: string;
  permissions: PermissionScope[];
}

export type LoginDto = {
  login: string;
  password: string;
}

export type RefreshTokenDto = {
  refreshToken: string;
}

export type LoginResponseDto = {
  authorizedUser: AuthorizedUser;
  accessToken: string;
  refreshToken: string;
}
