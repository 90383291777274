import {
  INVOICE_STATUS_CLOSED,
  INVOICE_STATUS_DEBT,
  INVOICE_STATUS_NEW,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIAL_PAID,
} from '@sr/dto';

export const DEFAULT_INVOICE_STATUSES = [
  INVOICE_STATUS_NEW,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIAL_PAID,
  INVOICE_STATUS_DEBT,
  INVOICE_STATUS_CLOSED,
];


export const INVOICE_STATUS_QUERY_KEY = 'statusIds'
