import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import { useMediaDropdown } from './hook';
import { MediaInfoDto } from '@sr/dto';

interface Props {
  option: MediaInfoDto;
}


export const MediaOption = ({ option, ...props }: Props) => {

  return (
    <Stack {...props} direction="column" sx={{ alignItems: 'flex-start' }}>
      <span style={{ textAlign: 'left', width: '100%' }}>
        {option.name}
      </span>
      <span style={{ textAlign: 'left', width: '100%', color: 'grey', fontSize: '0.875rem' }}>
        Город: {option.city.name} / Тип СМИ: {option.mediaType.name}
      </span>
    </Stack>
  );
};

export const MediaPickerField = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
  } = props;

  const [filter, setFilter] = useState('');

  const [open, setOpen] = useState(false);
  const { list, loading: fetching } = useMediaDropdown(filter);
  const [selectedMedia, setSelectedMedia] = useState<MediaInfoDto | null>(null);

  const onChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: MediaInfoDto | null) => {
      setSelectedMedia(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setFieldValue, name, setSelectedMedia],
  );

  const onInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    if (!list.length) return;
    setSelectedMedia(list.find(x => x.id === value) ?? null);
  }, [list, value]);


  const loading = open && fetching;

  return (
    <Autocomplete
      value={selectedMedia}
      autoComplete={false}
      options={list}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInput={onInput}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      loading={loading}
      filterOptions={(options, state) => options}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      renderOption={(props, option) => <MediaOption {...props} option={option} />}
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          name={name}
          label={label}
          size="small"
          placeholder="Начните набирать..."
        />
      }
    />);
};
