import { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { IPagingData, PurchaseDto } from '@sr/dto';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import { LinkToPurchase } from 'shared/ui/links/link-to-purchase';
import PriceSpan from 'shared/ui/price-span';
import { formatDatesAndPeriod } from 'utils/date-format-helpers';
import { calcPurchaseProfit } from 'entities/invoice-report/related-purchases.hook';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';

const columns: HeadCellsCollection = ['Заявка', 'Поставщик', 'СМИ', 'Cчет', 'Подробности', 'Количество', 'Период', 'Стоимость'];

type Props = {
  collection: IRemotePagedCollection<PurchaseDto>,
  paging: IPagingData,
  onChangePage: (paging: IPagingData) => void
}

export const MediaPurchasesList = ({ collection, paging, onChangePage }: Props) => {

  return (
    <>
      <GridLayout<PurchaseDto>
        columns={columns}
        items={collection.collection.items}
        pagingData={paging}
        onChangePaging={onChangePage}
        totalItemsCount={collection.collection.totalItemsCount}
        isLoading={collection.isLoading}
        error={collection.error}
        itemRender={purchase => (<PurchaseRow key={purchase.id} purchase={purchase} />)}
        noItemsText='Нет заявок'
      />
    </>
  );
};

const PurchaseRow = ({ purchase }: { purchase: PurchaseDto; }) => {

  const mediaName = purchase.clientInvoiceRow.media &&
    `${purchase.clientInvoiceRow.media.name}, г. ${purchase.clientInvoiceRow.media.city.name}`;

  return (
    <TableRow>
      <TableCell>
        <LinkToPurchase purchase={purchase} />
      </TableCell>
      <TableCell>
        {purchase.supplierInvoice
          ? <LinkToCompany company={purchase.supplierInvoice.sellerCompany} />
          : '-'
        }
      </TableCell>
      <TableCell>
        {purchase.clientInvoiceRow?.media
          ? <LinkToMedia media={purchase.clientInvoiceRow?.media} >{mediaName}</LinkToMedia>
          : '-'
        }
      </TableCell>
      <TableCell sx={{ minWidth: 200 }}>
        <div>{purchase.supplierInvoice ? <LinkToInvoice invoice={purchase.supplierInvoice} /> : '-'}</div>
        <div><LinkToInvoice invoice={purchase.clientInvoice} /></div>
      </TableCell>
      <TableCell>
        <div>
          {purchase.clientInvoiceRow.description}
          {purchase.clientInvoiceRow.media && <>(<LinkToMedia media={purchase.clientInvoiceRow.media} />)</>}
        </div>
        <div>
          {purchase.supplierInvoiceRow ? purchase.supplierInvoiceRow.description : '-'}
        </div>
      </TableCell>
      <TableCell>
        <div>{purchase.clientInvoiceRow.amount} шт </div>
        <div> {purchase.supplierInvoiceRow?.amount} шт</div>
      </TableCell>
      <TableCell>
        <div>{formatDatesAndPeriod(purchase.clientInvoiceRow.startDate, purchase.clientInvoiceRow.endDate)}</div>
        <div > {
          purchase.supplierInvoiceRow
            ? formatDatesAndPeriod(purchase.supplierInvoiceRow.startDate, purchase.supplierInvoiceRow.endDate)
            : '-'}
        </div >
      </TableCell>

      <TableCell align='right'>
        <div><PriceSpan size='small' price={purchase.clientInvoiceRow.priceDiscount} /></div>
        <div>{
          purchase.supplierInvoiceRow
            ? <PriceSpan size='small' price={purchase.supplierInvoiceRow.priceDiscount} />
            : '-'}
        </div>
      </TableCell>

      {/* <TableCell align='right'>
        {profit ? <PriceSpan price={profit} /> : '-'}
      </TableCell> */}

    </TableRow>);
};


