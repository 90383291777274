type NEW = 1;
type PAID = 2;
type PARTIAL_PAID = 3;
type DEBT = 4;
type CLOSED = 5;
type CANCELED = 6;
type WCC_CREATED = 7;

export const INVOICE_STATUS_NEW: NEW = 1;
export const INVOICE_STATUS_PAID: PAID = 2; //
export const INVOICE_STATUS_PARTIAL_PAID: PARTIAL_PAID = 3;
export const INVOICE_STATUS_DEBT: DEBT = 4;
export const INVOICE_STATUS_CLOSED: CLOSED = 5; //
export const INVOICE_STATUS_CANCELED: CANCELED = 6; //
export const INVOICE_STATUS_WCC_CREATED: WCC_CREATED = 7;

export type InvoiceStatus =
  NEW |
  PAID |
  PARTIAL_PAID |
  DEBT |
  CLOSED |
  CANCELED |
  WCC_CREATED;

export const INVOICE_STATUSES: { [key in InvoiceStatus]: string } = {
  [INVOICE_STATUS_NEW]: 'Выписан',
  [INVOICE_STATUS_PAID]: 'Оплачен',
  [INVOICE_STATUS_PARTIAL_PAID]: 'Частично оплачен',
  [INVOICE_STATUS_DEBT]: 'Долг',
  [INVOICE_STATUS_CLOSED]: 'Закрыт',
  [INVOICE_STATUS_CANCELED]: 'Отменен',
  [INVOICE_STATUS_WCC_CREATED]: 'Создан акт'
};

export const AVAILABLE_FOR_CHANGE_CANCEL_STATUSES: InvoiceStatus[] = [INVOICE_STATUS_CANCELED, INVOICE_STATUS_NEW];
