import { Box, Button, DialogActions, Stack } from '@mui/material';
import { ErrorBanner } from 'shared/ui/error-banner';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { Dialog } from 'shared/ui/dialog';
import { useActionCreators, useStateSelector } from 'shared/store';
import { wccExportActions } from '../export-list/store-slice';
import { useWccExport } from '../lib';
import { downloadFile } from 'utils/download-file';

type Props = {
    onSuccess?: () => void;
};

export const WccExportProgressDialog = ({ onSuccess }: Props) => {
  const open = useStateSelector(state => state.wcc.export.dialogOpen);
  const idList = useStateSelector(state => state.wcc.export.checkedItems);
  const { closeDialog, uncheckAll } = useActionCreators(wccExportActions);

  const { runExport, report, loading, error, reset } = useWccExport();

  const handleClose = () => {
    reset();
    closeDialog();
  };

  const handleExport = () =>
    runExport(idList)
      .then((report) => {
        uncheckAll();
        onSuccess?.();
        const file = report.files[0];
        downloadFile(`/api/download/temp-file/${file.downloadPath}`, file.fileName);
      });

  const renderContent = () => {
    if (loading)
      return <LoadingBanner/>;

    if (error)
      return <ErrorBanner errorMessage={error}/>;

    if (report)
      return (<Box>
        <LegendList>
          <LegendItem title="Успешно экспортировано актов:" value={report.exportedCount}/>
        </LegendList>
      </Box>);

    return (
      <Box>
        {`Экспортировать ${idList.length} актов?`}
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
    >
      <Dialog.Title>
                Экспорт актов
        {/* <Dialog.CloseButton onClick={() => props.onClose?.({}, 'escapeKeyDown')} /> */}
      </Dialog.Title>

      <Dialog.Content>
        {renderContent()}
      </Dialog.Content>

      <DialogActions sx={{ flex: 1, flexDirection: 'row' }}>
        <Stack direction="row" justifyContent="flex-start" spacing={1}>
          <Button
            variant="contained"
            disabled={loading || !!report}
            onClick={handleExport}
          >
                        экспортировать
          </Button>

          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleClose}
          >
                        закрыть
          </Button>
        </Stack>
      </DialogActions>

    </Dialog>);
};
