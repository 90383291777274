import { fileFieldToDto, FormDataFileField } from 'utils/form-tools';
import { LeadMediaplanDto, UpdateLeadMediaplanDto } from '@sr/dto';
import { fromNullableNumberField, toNullableNumberField } from 'utils/form-helper';
import { formatDateTime } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';

export type FormValues = {
  cityId: number | '';
  mediaTypeId: number | '';
  offerFile: FormDataFileField;
  createdBy: string;
  createdAt: string;
};

export const toDto = (values: FormValues): UpdateLeadMediaplanDto => ({
  // id: id,
  offerFile: fileFieldToDto(values.offerFile),
  cityId: toNullableNumberField(values.cityId),
  mediaTypeId: toNullableNumberField(values.mediaTypeId),
});

export const fromDto = (dto: LeadMediaplanDto): FormValues => ({
  cityId: fromNullableNumberField(dto.cityId),
  mediaTypeId: fromNullableNumberField(dto.mediaTypeId),
  offerFile: dto.offerFile,
  createdBy: dto.createdBy ? formatPerson(dto.createdBy) : '',
  createdAt: formatDateTime(dto.createdAt),
});

export const initialValues = (): FormValues => ({
  cityId: '',
  mediaTypeId: '',
  offerFile: null,
  createdBy: '',
  createdAt: '',
});
