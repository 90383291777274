import { Button } from '@mui/material';
import { useSnack } from 'shared/ui/snack';
import { invoiceCanceledStatusApi } from './api';

export const InvoiceCancelStatus = ({ invoiceId }: { invoiceId: number }) => {
  const {
    data,
    isFetching,
    isError,
  } = invoiceCanceledStatusApi.useGetInvoiceCanceledStatusQuery(invoiceId, { refetchOnMountOrArgChange: true });
  const [changeCancelStatus, { isLoading: isUpdating }] = invoiceCanceledStatusApi.useUpdateInvoiceCanceledStatusMutation();

  const { showSuccess, showError } = useSnack();

  const isCanceled = data ? data.isCanceled : true;

  const updateInvoiceStatus = () => {
    changeCancelStatus({ invoiceId })
      .unwrap()
      .then(() => showSuccess(`Счет ${!isCanceled ? 'отменён' : 'восстановлен'}`))
      .catch(e => showError('Ошибка изменения статуса счета', e));
  };

  return (
    <Button
      variant="contained"
      onClick={() => updateInvoiceStatus()}
      disabled={isFetching || isUpdating || isError}>
      {isCanceled ? 'Восстановить' : 'Отменить'}
    </Button>
  );
};
