import { Transform, Type } from 'class-transformer';

export class PayOrderExportFilter {
  @Type()
  ourLegalEntityId?: number;

  @Type()
  bankAccountId?: number;
}

export class PayorderFilter extends PayOrderExportFilter {
  groupIds?: number[];
  statusId?: number;

  @Type()
  fromDate?: Date;
  @Type()
  toDate?: Date;
}
