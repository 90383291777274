/* eslint-disable max-len */
import { Identifiable } from '@sr/dto';
import { useEffect, useState } from 'react';

export interface IRemoteCollection<T> {
    items: Array<T>;
    isLoading: boolean;
    error: string;
    reload: () => void
}

interface ReadOnlyRemoteCollectionConfig<TItem extends Identifiable> {
    fetchHandler?: () => Promise<TItem[]>;
    sort?: (a: TItem, b: TItem) => number;
}

interface RemoteCollectionConfig<
    TItem extends Identifiable,
    TCreateItem extends {} | void,
    TUpdateItem extends Identifiable | void = void>
    extends ReadOnlyRemoteCollectionConfig<TItem> {
    postHandler?: (itemData: TCreateItem) => Promise<TItem>;
    updateHandler?: (itemData: TUpdateItem, current?: TItem) => Promise<TItem>;
    deleteHandler?: (id: number) => Promise<void>;
}

interface LinkRemotecollectionConfig<TItem extends Identifiable, TCreateItem> extends ReadOnlyRemoteCollectionConfig<TItem> {
    postHandler?: (itemData: TCreateItem) => Promise<TItem>;
    deleteHandler?: (id: number) => Promise<void>;
}

export const useReadOnlyRemoteCollection = <TItem extends Identifiable>(config: ReadOnlyRemoteCollectionConfig<TItem>): IRemoteCollection<TItem> => {
  const { fetchHandler, sort } = config;

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [list, setList] = useState<TItem[]>([]);

  const reload = () => {
    if (fetchHandler === undefined) return;

    setLoading(true);

    fetchHandler()
      .then(response => setList(sort ? response.sort(sort) : response))
      .catch(e => setError(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(reload, [fetchHandler, sort]);

  return {
    items: list,
    isLoading,
    error,
    reload
  };
};


export const useRemoteCollection =
    <
        TItem extends Identifiable,
        TCreateItem extends {} | void = void,
        TUpdateItem extends Identifiable | void = void>
  (config: RemoteCollectionConfig<TItem, TCreateItem, TUpdateItem>) => {
      const { fetchHandler, postHandler, updateHandler, deleteHandler, sort } = config;

      const [loading, setLoading] = useState(false);
      const [error, setError] = useState('');
      const [list, setList] = useState<TItem[]>([]);

      const reload = () => {
        if (fetchHandler === undefined) return;

        setLoading(true);

        fetchHandler()
          .then(response => setList(sort ? response.sort(sort) : response))
          .catch(e => setError(e.message))
          .finally(() => setLoading(false));
      };

      useEffect(reload, [fetchHandler, sort]);


      const addItem = postHandler ? (async (createData: TCreateItem) => {
        const response = await postHandler(createData);
        const newList = [...list, response];
        setList(sort ? newList.sort(sort) : newList);
        return response;
      }) : async () => {
        throw new Error('Post handler is not specified');
      };

      const updateItem = updateHandler
        ? (async (id: number, data: TUpdateItem) => {
          const current = list.find(x => x.id === id);
          const response = await updateHandler(data, current);
          setList(list.map(x => x.id === id ? response : x));
          return response;
        })
        : async () => {
          throw new Error('Update handler is not specified');
        };

      const removeItem = deleteHandler
        ? (async (id: number) => {
          await deleteHandler(id);
          setList(list.filter(x => x.id !== id));
        })
        : async () => {
          throw new Error('Delete handler is not specified');
        };


      return {
        collection: {
          items: list,
          isLoading: loading,
          error: error,
          reload: reload
        } satisfies IRemoteCollection<TItem>,
        addItem,
        updateItem,
        removeItem
      };
    };


export const useLinkRemoteCollection = <TItem extends Identifiable, TCreateItem>(config: LinkRemotecollectionConfig<TItem, TCreateItem>) => {
  const { fetchHandler, postHandler, deleteHandler } = config;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [list, setList] = useState<TItem[]>([]);

  useEffect(() => {
    if (fetchHandler === undefined) return;

    setLoading(true);

    fetchHandler()
      .then(response => setList(response))
      .catch(e => setError(e.message))
      .finally(() => setLoading(false));
  }, [fetchHandler]);


  const addItem = postHandler ? (async (createData: TCreateItem) => {
    const response = await postHandler(createData);
    setList([...list, response]);
    return response;
  }) : async () => {
    throw new Error('Post handler is not specified');
  };

  const removeItem = deleteHandler
    ? (async (id: number) => {
      await deleteHandler(id);
      setList(list.filter(x => x.id !== id));
    })
    : async () => {
      throw new Error('Delete handler is not specified');
    };

  return {
    collection: {
      items: list,
      isLoading: loading,
      error,
      reload: () => {
      }
    },
    addItem,
    removeItem
  };
};

