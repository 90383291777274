import { DownloadFileDto } from './download-file.dto';
import { UserDto } from './user.dto';

export class PayOrdersExportDto {
  bankAccount: string
  sum: number
  count: number
  payOrderIds: number[]
  file: DownloadFileDto
}

export class PayOrdersExportHistoryDto{
  id: number;
  createdAt: Date;
  createdBy: UserDto;
  exportReport: PayOrdersExportDto[]
}
