import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { payOrdersExportApi } from './pay-orders-export.api';
import { emptyPagedCollection, IPagedCollection, IPagingData, PayOrderDto, PayOrderExportFilter, PayOrdersExportHistoryDto } from '@sr/dto';

export const usePayOrdersExportCollection = (searchParams?: URLSearchParams) => {
  const filter: PayOrderExportFilter = {
    ourLegalEntityId: isNaN(Number(searchParams?.get('ourLegalEntityId') ?? NaN))
      ? undefined
      : Number(searchParams?.get('ourLegalEntityId')),
    bankAccountId: isNaN(Number(searchParams?.get('bankAccountId') ?? NaN)) || searchParams?.get('bankAccountId') === ''
      ? undefined
      : Number(searchParams?.get('bankAccountId')),
  };

  const { data, isLoading, error } = payOrdersExportApi.useGetPayOrdersForExportQuery(
    { filter },
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    collection: data ?? emptyPagedCollection as IPagedCollection<PayOrderDto>,
    isLoading,
    error: extractNestJsErrorMessage(error) };
};

export const usePayOrdersExportHistory = (paging: IPagingData)=>{
  const { data, isLoading, error } = payOrdersExportApi.useGetPayOrdersExportHistoryQuery({ paging });
  return {
    items: data ?? emptyPagedCollection as IPagedCollection<PayOrdersExportHistoryDto>,
    isLoading,
    error: extractNestJsErrorMessage(error),
  };
};

export const usePayOrdersExport = () => {
  const [postExport, { data, isLoading, error }] = payOrdersExportApi.useRunPayOrdersExportMutation();

  const runExport = async (idList: number[]) => {
    if (idList.length === 0) return Promise.reject();

    return postExport(idList).unwrap();
  };


  return {
    runExport,
    reports: data || null,
    loading: isLoading,
    error: extractNestJsErrorMessage(error),
    // reset
  };
};
