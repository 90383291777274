import { Button, Stack } from '@mui/material';
import {
  CreatePayOrderDto,
  CreatePrepaymentDto,
  PayOrderDto,
  PrepaymentDto,
  UpdatePayOrderDto,
  UpdatePrepaymentDto,
} from '@sr/dto';
import { FormDialog } from 'shared/ui/form-dialog';
import { PayOrderFormFields } from 'components/PayOrders/pay-order.form-fields';
import { notificationsConfig, PayOrdersList } from 'components/PayOrders/pay-orders-list';
import { FormData, formTools } from 'components/PayOrders/pay-order.form-tools';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { usePayOrders } from 'hooks/payorders.hook';
import { useSnack } from 'shared/ui/snack';
import { fetchPayOrder } from 'shared/api/payorders';
import { useTitleHook } from 'hooks/page-title.hook';
import { Heading } from 'shared/ui/list-page-heading';
import { usePagingParams } from 'shared/hooks/paging-params';
import * as React from 'react';
import { useRef, useState } from 'react';
import { ButtonLink } from 'shared/ui/link-base';
import AddIcon from '@mui/icons-material/Add';
import {
  useDeletePrepayment,
} from '../../entities/prepayment/prepayment.hook';
import { useActionCreators } from '../../shared/store';
import { prepaymentDialogActions } from '../../entities/prepayment/prepayment-dialog/prepayment-dialog.slice';
import { fetchPrepayment } from '../../entities/prepayment/prepayment.api';
import { PrepaymentDialogForm } from '../../entities/prepayment/prepayment-dialog/prepayment-dialog.form';


const PayOrdersListPage = () => {
  useTitleHook('Бухгалтерия');
  const { showSuccessDelete, showFailedDelete } = useSnack(notificationsConfig);
  const showConfirm = useConfirmDialog();

  const [paging, setPaging] = usePagingParams();
  const [filterString, setFilterString] = useState('');

  const { collection, addItem, updateItem, removeItem } = usePayOrders(paging, filterString);

  const prepaymentToEdit = useRef<PrepaymentDto | null>(null)
  const { openDialog: openPrepaymentDialog } = useActionCreators(prepaymentDialogActions);

  const {
    dialogProps,
    openToAdd,
    openToEdit,
    itemToEdit,
  } = useCreateOrEditDialog<PayOrderDto, CreatePayOrderDto, UpdatePayOrderDto, FormData>({
    addHandler: async (dto) => {
      await addItem(dto);
    },
    updateHandler: async (dto) => {
      await updateItem(dto);
    },
    formTools,
    notificationsConfig,
  });

  const deletePrepayment = useDeletePrepayment();

  const handleAdd = () => {
    prepaymentToEdit.current = null
    openPrepaymentDialog()
  }


  const handleDelete = (payOrder: PayOrderDto) => {
    showConfirm({
      content: 'Удалить платежное поручение?',
    })
      .then(() => {
        if (payOrder.rows && payOrder.rows[0]?.salaryEntryId) {
          deletePrepayment(payOrder.rows[0].salaryEntryId);
          return;
        }
        removeItem(payOrder.id);
      })
      .then(() => showSuccessDelete())
      .catch(e => showFailedDelete(e));
  };

  const handleOpenForEdit = async (payOrder: PayOrderDto) => {
    if (payOrder.rows && payOrder.rows[0]?.salaryEntryId) {
      fetchPrepayment(payOrder.rows[0]?.salaryEntryId).then(p=> {
        prepaymentToEdit.current = p
        openPrepaymentDialog()
      })
      return;
    }

    fetchPayOrder(payOrder.id)
      .then((item) => {
        openToEdit(item);
      });
  };

  return (
    <>
      <Heading
        title="Бухгалтерия"
        actions={
          <Stack spacing={1} direction="row"
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAdd}
            >
              Внести аванс
            </Button>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              onClick={openToAdd}
            >
              Внести платежное поручение
            </Button>
            <ButtonLink
              buttonProps={{ variant: 'contained' }}
              to="/accounting/import"
            >
              Импорт
            </ButtonLink>

            <ButtonLink
              buttonProps={{ variant: 'contained' }}
              to="/accounting/export"
            >
              Экспорт
            </ButtonLink>
          </Stack>

        } />

      <PayOrdersList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        filterString={filterString}
        onFilter={setFilterString}
        onEditClick={handleOpenForEdit}
        onDeleteClick={handleDelete} />

      <FormDialog<FormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новое платежное поручение' : 'Платежное поручение'}
        renderForm={(props) => <PayOrderFormFields {...props} isNew={!itemToEdit} />}
      />

      <PrepaymentDialogForm prepayment={prepaymentToEdit} />

    </>
  );
};

export default PayOrdersListPage;
