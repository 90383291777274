import { Field } from 'formik';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { BaseUrlParamsFilter, BaseUrlParamsFilterProps } from './base-url-params-filter';

type Props = BaseUrlParamsFilterProps & {
  companyIdFilter?: number, // filters only legal entities from specific company
}

export const LegalEntityUrlParamsFilter = ({ queryParamName, companyIdFilter, label = 'Юр. лицо', size = 'medium' }: Props) => {
  return (
    <BaseUrlParamsFilter queryParamName={queryParamName}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        disableInactive={false}
        companyIdFilter={companyIdFilter}
        size={size}
        component={LegalEntityPicker}
      />
    </BaseUrlParamsFilter>
  );
};
