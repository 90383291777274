import { payOrdersApi } from '../pay-orders.api';
import {
  PayOrdersExportDto,
  IPagedCollection,
  PayOrderDto,
  PayOrderExportFilter,
  PayOrdersExportHistoryDto, IPagingData,
} from '@sr/dto';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';

export const payOrdersExportApi = payOrdersApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayOrdersForExport: builder.query<IPagedCollection<PayOrderDto>, { filter: PayOrderExportFilter }>({
      query: ({ filter }) => ({
        url: 'export',
        method: 'GET',
        params: filter
      }),
    }),
    runPayOrdersExport: builder.mutation<PayOrdersExportDto[], number[]>({
      query: (idList: number[]) => ({
        url: 'export',
        method: 'POST',
        body: idList
      }),
    }),
    getPayOrdersExportHistory: builder.query<IPagedCollection<PayOrdersExportHistoryDto>, {paging: IPagingData}>({
      query: ({ paging }) => ({
        url: 'export-history',
        method: 'GET',
        params: paging && pagingDataToParams(paging),
      })
    })
  }),
});
