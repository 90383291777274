import { TableCell, TableRow } from '@mui/material';
import { PurchaseDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import { LinkToPurchase } from 'shared/ui/links/link-to-purchase';
import PriceSpan from 'shared/ui/price-span';
import { formatDatesAndPeriod } from 'utils/date-format-helpers';
import { IRemoteCollection } from 'utils/remote-collection.hook';
import { calcPurchaseProfit } from './related-purchases.hook';
import { LinkToInvoice } from '../client-invoices-list/ui/link-to-invoice';

type Props = {
    purchases: IRemoteCollection<PurchaseDto>;
    isCompact?: boolean;
};

export const RelatedPurchases = ({ purchases, isCompact = false }: Props) => {
  const columns =  ['№ Счета', 'Подробности', 'Количество', 'Период', 'Стоимость', 'Прибыль'];
  if (!isCompact){
    columns.unshift('№ Заявки', );
  }
  return (
    <>
      <GridLayout<PurchaseDto>
        columns={columns}
        items={purchases.items}
        isLoading={purchases.isLoading}
        error={purchases.error}
        itemRender={purchase => (<PurchaseRow key={purchase.id} purchase={purchase} isCompact={isCompact}/>)}
        noItemsText='Нет заявок'
      />
    </>
  );
};

const PurchaseRow = ({ purchase, isCompact }: { purchase: PurchaseDto; isCompact: boolean}) => {
  const profit = calcPurchaseProfit(purchase);

  return (
    <TableRow>
      {!isCompact &&
        <TableCell>
          <LinkToPurchase purchase={purchase}/>
        </TableCell>
      }
      <TableCell>
        <div><LinkToInvoice invoice={purchase.clientInvoice} />
        </div>
        <div>
          {purchase.supplierInvoice ? <LinkToInvoice invoice={purchase.supplierInvoice} /> : '-'}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {purchase.clientInvoiceRow.description}
          {purchase.clientInvoiceRow.media && <>(<LinkToMedia media={purchase.clientInvoiceRow.media} />)</>}
        </div>
        <div>
          {purchase.supplierInvoiceRow ? purchase.supplierInvoiceRow.description : '-'}
        </div>
      </TableCell>
      <TableCell>
        <div>{purchase.clientInvoiceRow.amount} шт</div>
        <div> {purchase.supplierInvoiceRow ? purchase.supplierInvoiceRow.amount + ' шт' : '-'} </div>
      </TableCell>
      <TableCell>
        <div>{formatDatesAndPeriod(purchase.clientInvoiceRow.startDate, purchase.clientInvoiceRow.endDate)}</div>
        <div> {
          purchase.supplierInvoiceRow && purchase.supplierInvoiceRow.startDate && purchase.supplierInvoiceRow.endDate
            ? formatDatesAndPeriod(purchase.supplierInvoiceRow.startDate, purchase.supplierInvoiceRow.endDate)
            : '-'}
        </div>
      </TableCell>

      <TableCell>
        <div><PriceSpan size='small' price={purchase.clientInvoiceRow.priceDiscount} /></div>
        <div>{
          purchase.supplierInvoiceRow
            ? <PriceSpan size='small' price={purchase.supplierInvoiceRow.priceDiscount} />
            : '-'}
        </div>
      </TableCell>

      <TableCell>
        {profit ? <PriceSpan price={profit} /> : '-'}
      </TableCell>

    </TableRow>);
};


