import { InvoiceReportRowDto } from '@sr/dto';
import { InvoiceReportTotals } from './invoice-report-rows';

/**
 * Подсчитывает итоги по отчету по счету. Суммы строк клиентских и поставщиков, частичные оплаты и т.д.
 * @param rows
 * @returns
 */
export const calculateInvoiceReportTotals = (
  rows: InvoiceReportRowDto[]
): InvoiceReportTotals => ({
  clientRowsTotal: rows.reduce(
    (acc, row) => acc + row.clientRow.priceDiscount,
    0
  ),
  supplierRowsTotal: rows.reduce(
    (acc, row) => acc + (row.supplierRow?.priceDiscount ?? 0),
    0
  ),

  clientPartialPaidTotal: rows.reduce(
    (acc, row) => acc + (row.clientRow.paidAmount ?? 0),
    0
  ),
  supplierPartialPaidTotal: rows.reduce(
    (acc, row) => acc + (row.supplierRow?.paidAmount ?? 0),
    0
  ),

  totalProfitByPayments: rows.reduce(
    (acc, row) => acc + (row.profitByPayments ?? 0),
    0
  ),
  totalProfitByPurchases: rows.reduce(
    (acc, row) => acc + (row.profitByPurchases ?? 0),
    0
  ),
});
