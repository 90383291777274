import { Box, Stack } from '@mui/material';
import { CardPaper } from 'shared/ui/card-paper';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { InvoiceStatusUrlParamsFilter, } from 'entities/filters/invoice-status-url-params-filter';
import { ManagerUrlParamsFilter } from 'entities/filters/manager-url-params-filter';
import { DateUrlParamsFilter } from 'entities/filters/date-url-params-filter';
import {
  LegalEntityUrlParamsFilter,
} from 'entities/filters/legal-entity-url-params-filter';

export const InvoiceFilter = () => {
  const ourCompanyId = useOurCompany();
  return (
    <CardPaper sx={{ mb: 2 }}>
      <Stack sx={{ mb: 2 }}>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flexBasis: 400, maxWidth: 400, flexGrow: 1 }}>
            <ManagerUrlParamsFilter queryParamName='managerId'/>
          </Box>
          <Box sx={{ flexBasis: 300,flexGrow: 1 }}>
            <DateUrlParamsFilter queryParamName={'fromDate'} label={'Дата с'}/>
          </Box>
          <Box sx={{ flexBasis: 300 ,flexGrow: 1 }}>
            <DateUrlParamsFilter queryParamName={'toDate'} label={'Дата по'}/>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} >
        <Box sx={{ flexBasis: 325, maxWidth: 400, flexGrow: 1 }}>
          <InvoiceStatusUrlParamsFilter queryParamName={'statusIds'}/>
        </Box>
        <Box sx={{ flexBasis: 300, flexGrow: 2 }}>
          <LegalEntityUrlParamsFilter queryParamName={'ourLegalEntityId'} companyIdFilter={ourCompanyId} label={'Наше юр. лицо'}/>
        </Box>
      </Stack>

    </CardPaper>
  );
};
