import { salaryApi } from 'entities/salary/salary.api';
import { CreatePrepaymentDto, PrepaymentDto, PurchaseDto, UpdatePrepaymentDto } from '@sr/dto';
import { authorizedRequest, fetchReq } from '../../shared/api/axios';
import { APP_BASE_URL, SALARY_BASE_URL } from '../../shared/api/api';


export const prepaymentApi = salaryApi.injectEndpoints({
  endpoints: (builder) => ({
    createPrepayment: builder.mutation<void, CreatePrepaymentDto>({
      query: (dto: CreatePrepaymentDto) => ({
        url: `prepayment`,
        body: dto,
        method: 'POST',
      }),
    }),

    updatePrepayment: builder.mutation<void, UpdatePrepaymentDto>({
      query: (dto: UpdatePrepaymentDto) => ({
        url: `prepayment/${dto.id}`,
        body: dto,
        method: 'PUT',
      }),
    }),

    deletePrepayment: builder.mutation<void, number>({
      query: (salaryEntryId: number) => ({
        url: `prepayment/${salaryEntryId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const fetchPrepayment = async (salaryEntryId: number) => {
  return await fetchReq<PrepaymentDto>(`${SALARY_BASE_URL}/prepayment/${salaryEntryId}`);

};
