import { format, formatDistanceStrict } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatDate = (date: Date | string): string => {
  if (!date) return '';

  if (typeof date === 'string')
    date = new Date(date);

  return format(date, 'd MMM yyyy', { locale: ru });
};

export const formatDateTime = (date: Date | string): string => {
  if (!date) return '';

  if (typeof date === 'string')
    date = new Date(date);

  return format(date, 'd MMM yyyy HH:mm', { locale: ru });
};

export const formatDatePeriod = (startDate: Date | string, endDate: Date| string): string => {
  if (!endDate || !startDate) return '--';
  return formatDistanceStrict(new Date(endDate), new Date(startDate), {
    locale: ru,
    unit: 'day'
  });
};

export const formatDatesAndPeriod = (startDate: Date |string, endDate: Date|string): string => {
  return `${formatDate(startDate)} - ${formatDate(endDate)} (${formatDatePeriod(startDate, endDate)})`;
};
