import {
  IsBoolean,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";
import { BankAccountType, BA_TYPES } from "./enums/bank-account-types";

export class BankAccountDto {
  id: number;
  isActive: boolean;
  type: BankAccountType;
  title: string;
  legalEntityId: number;
  bankDetailsId: number;
}

export class CreateBankAccountDto {
  @IsString()
  @IsNotEmpty()
  title: string;

  // @Type(() => Number)
  @IsIn([...Object.keys(BA_TYPES).map(Number)])
  @IsNumber()
  type: BankAccountType;

  @IsOptional()
  @IsNumber()
  legalEntityId?: number;

  @IsOptional()
  @IsNumber()
  bankDetailsId?: number;

  @IsBoolean()
  isActive: boolean;
}

export class UpdateBankAccountDto extends CreateBankAccountDto {
  @IsNumber()
  id: number;
}
