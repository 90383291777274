import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPrepaymentDialogOpened: false,
};

export const prepaymentDialog = createSlice({
    name: 'prepayment_dialog',
    initialState: initialState,
    reducers: {
      openDialog(state) {
        state.isPrepaymentDialogOpened = true;
      },
      closeDialog(state) {
        state.isPrepaymentDialogOpened = false;
      },
    },
  }
);

export const { reducer: prepaymentDialogReducer, actions: prepaymentDialogActions } = prepaymentDialog;
