import { Transform, Type } from 'class-transformer';
import {
  IsOptional,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsPositive,
  IsString, IsDate,
} from 'class-validator';
import { IManager } from './manager.dto';
import { UserDto } from './user.dto';

export type LinkType = "link" | "block";

export interface IManagerLink {
  id: number;
  companyId: number;
  manager: IManager;
  linkType: LinkType;
  startDate: Date;
  endDate: Date;
  isActive?: Boolean;
}

export type ICreateManagerLink = {
  companyId: number;
  managerId: number;
  linkType: LinkType;
  endDate: Date;
};

export type IDeleteManagerLink = {
  linkId: number;
};

export class CreateLinkManagerDto implements ICreateManagerLink {
  @IsNotEmpty()
  @IsInt()
  @IsPositive()
  managerId: number;

  @IsOptional()
  companyId: number;

  @IsNotEmpty()
  @IsString()
  @IsEnum(['link', 'block'])
  linkType: LinkType;

  @Type()
  @IsDate()
  @IsNotEmpty()
  @Transform( ({ value }) => new Date(value))
  endDate: Date;
}

export class ManagerLinkDto implements IManagerLink {
  id: number;
  companyId: number;
  manager: UserDto;
  linkType: LinkType;
  startDate: Date;
  endDate: Date;
}
