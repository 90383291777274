import { Divider, MenuItem, TextField } from '@mui/material';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import PriceSpan from 'shared/ui/price-span';
import { PaidProgress } from 'shared/ui/progress-bar';
import { CardPaper } from 'shared/ui/card-paper';
import { InvoiceReportTotals } from './invoice-report-rows';

type ProfitProps = {
  profits: InvoiceReportTotals
};

export const ProfitCard = ({ profits }: ProfitProps) => {
  const ramaining = profits.clientRowsTotal - (profits.clientPartialPaidTotal ?? 0);
  return (
    <CardPaper>
      <LegendList>
        <PaidProgress variant="determinate" value={100 * (profits.clientRowsTotal ? ((profits.clientPartialPaidTotal ?? 0) / profits.clientRowsTotal) : 0)} />
        <LegendItem title="Сумма:" value={<PriceSpan price={profits.clientRowsTotal} />} />
        <LegendItem title="Оплачено:" value={<PriceSpan price={profits.clientPartialPaidTotal ?? 0} />} />
        <LegendItem title="Еще:" value={<PriceSpan price={ramaining} />} />
        <Divider sx={{ p: 1 }} />
        <LegendItem title="Прибыль по заявкам:" value={<PriceSpan price={profits.totalProfitByPurchases} />} />
        {profits.totalProfitByPayments && <LegendItem title="Прибыль по платежкам:" value={<PriceSpan isApproximate price={profits.totalProfitByPayments} />} />}
        <Divider sx={{ p: 1 }} />
        <LegendItem title="Ставка:" value={
          <TextField size='small' value={1} select fullWidth>
            <MenuItem value={1}>10%</MenuItem>
            <MenuItem value={2}>20%</MenuItem>
          </TextField>} />
        <LegendItem title="ЗП менеджера:" value={<PriceSpan size='small' price={4500} />} />
      </LegendList >
    </CardPaper>
  );
};