import { useCallback, useEffect, useState } from 'react';
import { MediaInfoDto } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { fetchMediaDropdown } from './api';
import { usePagingParams } from '../../shared/hooks/paging-params';
import { debounce } from '@mui/material';


const DEBOUNCE_DELAY = 300;

export const useMediaDropdown = (filterString: string = '') => {
  const [paging, setPaging] = usePagingParams();
  const { showError } = useSnack();
  const [list, setList] = useState<MediaInfoDto[]>([]);
  const [loading, setLoading] = useState(false);

  const getMedias = async () => {
    setLoading(true);

    try {
      const response = await fetchMediaDropdown(paging, filterString);

      setList(response);
    } catch (error) {
      showError('Не удалось получить список СМИ');
    } finally {
      setLoading(false);
    }

  };

  const debouncedFetchMedias = useCallback(debounce(() => {
    getMedias();
  }, DEBOUNCE_DELAY), [filterString]);

  useEffect(() => {
    debouncedFetchMedias();
  }, [filterString]);

  useEffect(() => {
    return () => {
      debouncedFetchMedias.clear();
    };
  }, [debouncedFetchMedias]);

  return ({
    list: list,
    loading,
  });
};
