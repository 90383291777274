import { PurchaseDto } from '@sr/dto';
import { UpdatePurchaseDto } from 'shared/api/purchases';
import { fileFieldToDto, FormDataFileField } from 'utils/form-tools';

export type FormData = {
  paymentAmount: number | '',
  paymentDate: Date | '',
  status: number | '',

  invoiceScan: FormDataFileField,
  bankDetailsScan: FormDataFileField,
  wccScan: FormDataFileField,
};

type ValidationData = Partial<{ [x in keyof FormData]: string }>;

export const validate = (values: FormData): ValidationData => {
  let validationResult: ValidationData = {};

  return validationResult;
};

export const toDto = (purchaseId: number, formData: FormData) => {
  const dto: UpdatePurchaseDto = {
    id: Number(purchaseId),
    statusId: Number(formData.status),
    paymentAmount: Number(formData.paymentAmount),
    paymentDate: new Date(formData.paymentDate),
    invoiceScan: fileFieldToDto(formData.invoiceScan),
    bankDetailsScan: fileFieldToDto(formData.bankDetailsScan),
    wccScan: fileFieldToDto(formData.wccScan)
  };

  return dto;
};

export function fromDto(dto: PurchaseDto): FormData {
  const formData: FormData = {
    paymentAmount: dto.paymentAmount,
    paymentDate: dto.paymentDate,
    status: dto.status.id,
    invoiceScan: dto.invoiceScan,
    bankDetailsScan: dto.bankDetailsScan,
    wccScan: dto.wccScan,
  };
  return formData;
}
