import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { useFormik } from 'formik';
import { CreateLegalEntityDto } from '@sr/dto';
import { LegalEntityBasicForm, FormData, emptyValues, validate, toNewLegalEntity } from './legal-entity-basic-form';


type Props = {
  open: boolean;
  onSubmit: (legalEntityToCreate: CreateLegalEntityDto) => Promise<void>;
  onClose: () => void;
}

export const LegalEntityCreateDialog = (props: Props) => {
  const { open, onClose, onSubmit } = props;

  const handleSubmit = async (values: FormData) => {
    const entityToCreate: CreateLegalEntityDto = toNewLegalEntity(values);
    await onSubmit(entityToCreate);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: emptyValues,
    validate: validate,
    onSubmit: handleSubmit
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Новое юридическое лицо</DialogTitle>
      <DialogContent>
        <LegalEntityBasicForm formik={formik} />
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={formik.submitForm}
            disabled={formik.isSubmitting || !formik.dirty}>
            {formik.isSubmitting ? 'Подождите...' : 'Сохранить'}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={formik.isSubmitting}>
            Закрыть
          </Button>
        </DialogActions>
      </DialogContent>

    </Dialog >);
};
