import { IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar } from 'shared/ui/app-bar';
import { useUserAuth } from 'features/auth';
import { SearchBar } from 'entities/search';
import { useMatch } from 'react-router-dom';
import { useStateSelector } from '../store';


type Props = {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

export const TopBar = ({ drawerOpen, toggleDrawer }: Props) => {
  const { logout } = useUserAuth();
  const isOnSearchPage = useMatch('/search');
  const { user } = useStateSelector(store => store.auth);

  return (
    <AppBar position="absolute" open={drawerOpen}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
        </Typography>
        {!isOnSearchPage && <SearchBar />}

        <IconButton color="inherit">
          <NotificationsIcon />
        </IconButton>
        <Typography fontWeight = {500}>{user?.firstName} {user?.secondName}</Typography>
        <IconButton color="inherit" onClick={logout}>
          <LogoutIcon />
          {/* <NotificationsIcon /> */}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
