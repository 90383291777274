import { isBefore } from 'date-fns';
import { CreateInvoiceRowDto, InvoiceRowDto, UpdateInvoiceRowDto } from '@sr/dto';
import { VALIDATION_REQUIRED_FIELD } from '../../../utils/form-tools';


export type FormData = {
  id: number | '',
  mediaId: number | '';
  description: string;
  amount: number | '';
  price: number | '';
  priceDiscount: number | '';
  vat: number | '';
  startDate: Date | null;
  endDate: Date | null;
}

type ValidationData = Partial<Record<keyof FormData, string>>;

export const emptyValues: FormData = {
  id: '',
  mediaId: '',
  description: '',
  amount: '',
  price: '',
  priceDiscount: '',
  vat: '',
  startDate: new Date(),
  endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
};

export const creteNewRow = () => {
  const newRowDto: CreateInvoiceRowDto & { id: number } = {
    id: NaN,
    invoiceId: 0,
    startDate: new Date(),
    endDate: new Date(),
    //mediaId: NaN,
    description: '',
    amount: 1,
    price: 0,
    priceDiscount: 0,
    vat: 0,
    rowOrder: 0,
  };
  newRowDto.endDate.setMonth(newRowDto.startDate.getMonth() + 1);
  return newRowDto;
};

export const fromDto = (dto: InvoiceRowDto): FormData => {
  const formData: FormData = {
    id: dto.id,
    mediaId: dto.media ? dto.media.id : '',
    description: dto.description,
    startDate: dto.startDate ? new Date(dto.startDate) : null,
    endDate: dto.startDate ? new Date(dto.endDate) : null,
    amount: dto.amount,
    price: dto.price,
    priceDiscount: dto.priceDiscount,
    vat: dto.vat ? dto.vat : 0
  };
  return formData;
};

export const toDto = (originalRow: InvoiceRowDto, values: FormData): UpdateInvoiceRowDto => {
  if (!values.startDate || !values.endDate) {
    throw new Error('Начальная и конечная даты строчки счета должны быть заданы');
  }
  return {
    id: originalRow.id,
    invoiceId: originalRow.invoiceId,
    mediaId: values.mediaId ? Number(values.mediaId) : undefined,
    description: values.description,
    startDate: new Date(values.startDate),
    endDate: new Date(values.endDate),
    amount: Number(values.amount),
    price: Number(values.price),
    priceDiscount: Number(values.priceDiscount),
    vat: Number(values.vat),
    rowOrder: originalRow.rowOrder
  };
};

export const validate = (values: FormData): ValidationData => {
  const errors: ValidationData = {};

  if (!values.startDate) {
    errors.startDate = VALIDATION_REQUIRED_FIELD;
  }

  if (!values.endDate) {
    errors.endDate = VALIDATION_REQUIRED_FIELD;
  }


  if (values.endDate && values.startDate && isBefore(values.endDate, values.startDate)) {
    errors.startDate = errors.endDate = 'Дата начала не может быть позже даты конца';
  }

  if (Number(values.priceDiscount) > Number(values.price)) {
    errors.price = 'Стоимость со скидкой больше чем без скидки';
    errors.priceDiscount = 'Стоимость со скидкой больше чем без скидки';
  }

  return errors;
};
