import {
  CommentDto,
  CreateCommentDto,
  CreatePayOrderFromPurchaseDto,
  CreatePurchaseDto,
  IPagingData,
  LinkSupplierDto,
  PayOrderDto,
  PurchaseDto,
  PurchaseInfoDto,
  PurchaseStatusCheckDto,
  UnlinkSupplierDto,
} from '@sr/dto';
import { Type } from 'class-transformer';
import { IsDate, IsNumber } from 'class-validator';
import { authorizedFormDataRequest, authorizedRequest, fetchPagedReq, toFormData } from 'shared/api/axios';

export class UpdatePurchaseDto {
  @IsNumber()
  @Type()
    id: number;

  @Type()
  @IsNumber()
    paymentAmount: number;

  @IsDate()
  @Type(() => Date)
    paymentDate: Date = new Date();

  @Type()
  @IsNumber()
    statusId: number;

  // File - прикрепить новый файл
  // null - удалить прикрепленный файл
  // undefined - ничего не делать

  invoiceScan?: File | null;
  bankDetailsScan?: File | null;
  wccScan?: File | null;
}

const BASE_URL = '/purchases';

export const fetchPurchases = async (paging: IPagingData, filterString?: string) => {
  const params = new URLSearchParams(filterString);
  const response = await fetchPagedReq<void, PurchaseInfoDto>(`${BASE_URL}?${params.toString()}`, paging);
  return response;
};

export const deletePurchase = async (id: number) => {
  const response = await authorizedRequest<void, void>(`${BASE_URL}/${id}`, 'DELETE');

  return response;
};

export const fetchInvoicePurchases = async (invoiceId: number) => {
  const response = await authorizedRequest<void, PurchaseDto[]>(`${BASE_URL}/invoice/${invoiceId}`, 'GET');
  return response.map((x) => PurchaseDto.fromPlain(x));
};

export const fetchUnlinkedPurchases = async (statusId: number[]) => {
  const response = await authorizedRequest<void, PurchaseDto[]>(`${BASE_URL}/unlinked?statusIds=${statusId}`, 'GET');
  return response.map((x) => PurchaseDto.fromPlain(x));
};

export const postPurchase = async (dto: CreatePurchaseDto) => {
  const response = await authorizedRequest<CreatePurchaseDto, PurchaseInfoDto>(BASE_URL, 'POST', dto);
  return PurchaseDto.fromPlain(response);
};

export const patchPurchase = async (dto: UpdatePurchaseDto) => {
  // const response = await authorizedRequest<UpdatePurchaseDto, PurchaseDto>(
  //   BASE_URL,
  //   'PATCH',
  //   dto
  // );

  // const files: AttachmentFile[] = [];
  // dto.invoiceScan && files.push({ fieldName: 'invoiceScan', file: dto.invoiceScan });

  // dto.bankDetailsScan && files.push({ fieldName: 'bankDetailsScan', file: dto.bankDetailsScan });
  // dto.wccScan && files.push({ fieldName: 'wccScan', file: dto.wccScan });

  const data = toFormData(dto);

  const response = await authorizedFormDataRequest(BASE_URL + '/update', 'POST', data);

  return PurchaseDto.fromPlain(response);
};

// export const uploadPrice = (priceData: ICreatePrice, file: File) => {
//   return authorizedRequest<void, IMediaPriceInfo[]>(`/media/${mediaId}/prices`, 'GET');
// }

export const unlinkSupplierPurchase = async (dto: UnlinkSupplierDto) => {
  const response = await authorizedRequest<UnlinkSupplierDto, PurchaseDto>(`${BASE_URL}/unlink`, 'PUT', dto);

  return PurchaseDto.fromPlain(response);
};

export const linkSupplierPurchase = async (dto: LinkSupplierDto) => {
  const response = await authorizedRequest<LinkSupplierDto, PurchaseDto>(`${BASE_URL}/link`, 'PUT', dto);

  return PurchaseDto.fromPlain(response);
};

export const fetchComments = async (purchaseId: number) => {
  const response = await authorizedRequest<void, CommentDto[]>(`${BASE_URL}/${purchaseId}/comments`, 'GET');

  return response.map((r) => CommentDto.fromPlain(r));
};

export const postComment = async (purchaseId: number, dto: CreateCommentDto) => {
  const response = await authorizedRequest<CreateCommentDto, CommentDto>(`${BASE_URL}/${purchaseId}/comments`, 'POST', dto);

  return CommentDto.fromPlain(response);
};

export const patchPurchaseStatus = async (purchase: PurchaseStatusCheckDto) => {
  const response = authorizedRequest<PurchaseStatusCheckDto, PurchaseInfoDto>(`${BASE_URL}/updateStatus`, 'POST', purchase);
  return PurchaseDto.fromPlain(response);
};

export const createPayOrderFromPurchase = async (purchaseId: number, dto: CreatePayOrderFromPurchaseDto) => {
  const response =  authorizedRequest<CreatePayOrderFromPurchaseDto, PayOrderDto>(`${BASE_URL}/${purchaseId}/pay-order`, 'POST', dto)
  return response
}

// export const patchPurchaseStatus = async (purchase: object) => {
//     const response = authorizedRequest<{ purchase: object}, PurchaseInfoDto>(
//     `${BASE_URL}/updateStatus`,
//     'POST',
//     {purchase}
// );
//   return PurchaseDto.fromPlain(response);
// };

// export const patchPurchaseStatus = async (purchaseId: number, status: number) => {

//   console.log("STATUS TYT   " +status+"      Id TYT   "+purchaseId)
//   //const response = await authorizedFormDataRequest(
//     const response = authorizedRequest<{ status: number; }, PurchaseInfoDto>(
//     `${BASE_URL}/${purchaseId}/status`,
//     'POST',
//     {status}
// );
// console.log(PurchaseDto.fromPlain(response))
//   return true
//   //return PurchaseDto.fromPlain(response);
// };
