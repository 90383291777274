import { useCallback } from 'react';
import { InvoiceInfoDto, IPagingData } from '@sr/dto';
import { changeInvoiceCanceledStatus, fetchSupplierInvoices } from '../api';
import { useReadOnlyRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const useSupplierInvoices = (paging: IPagingData, companyId?: number, searchString?: string) => {

  const collection = useReadOnlyRemotePagedCollection<InvoiceInfoDto>({
    fetchHandler: useCallback(() => fetchSupplierInvoices(paging, searchString, companyId), [companyId, paging, searchString]),
  });

  const changeInvoiceStatus = (id: number) => changeInvoiceCanceledStatus(id);

  return {
    collection,
    changeCancelStatus: changeInvoiceStatus
  };
};


