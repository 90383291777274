import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CreateInvoiceRowDto, InvoiceRowDto, UpdateInvoiceRowDto } from '@sr/dto';
import { useMemo, useState } from 'react';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { creteNewRow } from './invoice-row.form';
import { ErrorBanner } from 'shared/ui/error-banner';
import { InfoBanner } from 'shared/ui/info-banner';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useNavigate } from 'react-router-dom';
import { purchaseLinkManager } from 'utils/link-manager';
import { linkSupplierPurchase, postPurchase, unlinkSupplierPurchase } from 'shared/api/purchases';
import LinkRowPurchaseDialog from 'components/Purchases/link-row-to-purchase-dialog';
import ErrorBoundary from 'app/error-boundary';
import { TotalsRow } from './totals-row';
import { InvoiceRow } from './invoice-row';
import { useInvoiceTotals } from '../totals';
import {
  useAddInvoiceRow, useDeleteInvoiceRow,
  useInvoiceRows,
  useUpdateInvoiceRow
} from 'entities/invoice-rows/invoice-rows.hook';


type Props = {
    invoiceId: number;
    invoiceRowId?: number;
    purchaseId?: number;
    buyerVat?: number;
    isClientInvoice: boolean;
    showNewRow: boolean;
    allowEdit: boolean;
    onCancelNewRow: () => void;
    isCompact?: boolean;
};

export const InvoiceRows = (props: Props) => {
  const { invoiceId, buyerVat, isClientInvoice, showNewRow, allowEdit, onCancelNewRow, isCompact = false, invoiceRowId, purchaseId } = props;
  const { totals, loading: isTotalsLoading, reload: reloadTotals } = useInvoiceTotals(invoiceId);
  const { rows, isLoading, error } = useInvoiceRows(invoiceId, invoiceRowId);
  const deleteRow = useDeleteInvoiceRow(invoiceId);
  const updateInvoiceRow = useUpdateInvoiceRow(purchaseId);
  const addInvoiceRow = useAddInvoiceRow();

  const [linkPurchaseDialogOpen, setLinkPurchaseDialogOpen] = useState(false);
  const [clickedLinkRow, setClickedLinkRow] = useState<InvoiceRowDto | undefined>();

  const showDialog = useConfirmDialog();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const columns = [
      'Услуга',
      // 'Описание',
      'Период',
      'Количество',
      'Цена',
      'Стоимость',
      'Ставка НДС, %',
    ];
    if (!isCompact)
      isClientInvoice ? columns.push('Размещение') : columns.push('Привязка');
    columns.push('');

    if (!isClientInvoice)
      columns.shift();

    return columns;
  },
  [isClientInvoice, isCompact]);

  const showConfirm = useConfirmDialog();
  const { showError, showSuccess } = useSnack();

  const deleteHandler = async (row: InvoiceRowDto) => {
    return showConfirm({
      content: 'Удалить услугу?'
    })
      .then(() => deleteRow(row.id))
      .then(reloadTotals)
      .then(() => showSuccess('Услуга успешно удалена'))
      .catch(e => showError(`Ошибка удаления услуги: ${e.message}`));
  };

  const addHandler = async (createDto: CreateInvoiceRowDto) => {
    createDto.invoiceId = invoiceId;
    return addInvoiceRow(createDto)
      .then(onCancelNewRow)
      .then(reloadTotals)
      .then(() => showSuccess('Услуга добавлена'))
      .catch(e => showError(`Ошибка добавления услуги: ${e}`));
  };

  const updateHanlder = async (updateDto: UpdateInvoiceRowDto) => {
    return updateInvoiceRow(updateDto)
      .then(reloadTotals)
      .then(() => showSuccess('Услуга обновлена'))
      .catch(e => showError(`Ошибка обновления услуги: ${e}`));
  };

  const handlePlacementClick = (row: InvoiceRowDto) => {
    if (isClientInvoice) {
      if (row.linkedClientPurchase) {
        navigate(purchaseLinkManager.link(row.linkedClientPurchase.id));
      } else {
        showDialog({
          title: 'Размещение',
          content: 'Создать заявку на размещение?'
        }).then(() =>
          postPurchase({ clientInvoiceRowId: row.id }))
          .then(newPurchase => {
            showSuccess('Заявка на размещение успешно создана');
            navigate(purchaseLinkManager.link(newPurchase.id));
          })
          .catch(e => showError(`Ошибка создания заявки: ${e}`));
      }
    } else {
      if (row.linkedSupplierPurchase) {
        const id = row.linkedSupplierPurchase.id;
        showDialog({
          title: 'Привязка',
          content: 'Отвязать затрату?'
        }).then(() =>
          unlinkSupplierPurchase({
            id: id,
          })
            .then(_ => {
              showSuccess('Привязка к затрате успешно удалена');
            })
            .catch(e => showError(`Ошибка отвязки затраты: ${e}`))
        );
      } else {
        setLinkPurchaseDialogOpen(true);
        setClickedLinkRow(row);
      }
    }
  };

  const onLinkPurchase = async (purchaseId: number, rowId: number) => {
    return linkSupplierPurchase({ purchaseId: purchaseId, invoiceRowId: rowId })
      .then(r => {
        setLinkPurchaseDialogOpen(false);
        showSuccess('Затрата успешно привязана к заявке');
      })
      .catch(e => showError(`Ошибка привязки затраты к заявке: ${e}`));
  };

  const newRowItem: InvoiceRowDto = { ...creteNewRow(), vat: buyerVat, priceExclVat: 0 };

  if (error)
    return (
      <Container>
        <ErrorBanner errorMessage={error}/>
      </Container>
    );

  if (isLoading)
    return (
      <Container>
        <LoadingBanner/>
      </Container>
    );

  if ((!rows || !rows?.length) && !showNewRow)
    return (
      <Container>
        <InfoBanner message={'Список услуг пуст'}/>
      </Container>
    );

  return (
    <ErrorBoundary>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow
              sx={{ bgcolor: 'grey.100' }}
            >
              {columns.map((header, idx) => <TableCell key={idx}>{header}</TableCell>)}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map(row => <InvoiceRow
              key={row.id}
              row={row}
              isReadOnly={!allowEdit}
              sellerVat={buyerVat || 0}
              isClientInvoice={isClientInvoice}
              onPlaceClick={() => handlePlacementClick(row)}
              onSaveClick={updateHanlder}
              onDeleteClick={deleteHandler}
              isCompact={isCompact}
            />)}

            {showNewRow && <InvoiceRow
              isNewRow
              row={newRowItem}
              sellerVat={buyerVat || 0}
              isClientInvoice={isClientInvoice}
              onSaveClick={addHandler}
              onCancelClick={onCancelNewRow}/>}

            {!isCompact && <TotalsRow totals={totals}/>}
          </TableBody>
        </Table>
      </TableContainer>

      {clickedLinkRow && <LinkRowPurchaseDialog
        open={linkPurchaseDialogOpen}
        onClose={() => setLinkPurchaseDialogOpen(false)}
        row={clickedLinkRow}
        onSubmit={onLinkPurchase}/>
      }
    </ErrorBoundary>);
};
