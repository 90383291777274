import { Stack } from '@mui/material';
import { Field, useFormikContext }from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { FormData, formTools } from './supplier-invoice.form-tools';
import { TextField } from 'formik-mui';
import { BankDetailsPicker } from 'shared/ui/formik-fields/bank-details-picker';
import { SignerPicker } from 'shared/ui/formik-fields/signer-picker';
import { UserPickerField } from 'features/user/user-picker/ui';
import { FormDialog } from 'shared/ui/form-dialog';
import { EntityRoles } from 'entities/invoice/types';
import { CompanyPickerField } from 'shared/ui/formik-fields/company-picker';
import { useBankDetailsDialog, useSignerDialog } from '../../../shared/providers/legal-entities.provider';
import { ExternalPickerContainer } from '../../../components/Mui/external-picker-container';
import { BankDetailsDialog } from '../../../components/Companies/BankDetails/bank-details-dialog';
import { SignerDialog } from '../../../components/Companies/Signers/signer-dialog';
import { INVOICE_PRINT_NOT_SHOW_DATE } from '@sr/dto';


const START = new Date();
const END = new Date();
END.setMonth(START.getMonth() + 1);


const initialValues: FormData =  {
  invoiceNumber: '',
  startDate: START,
  endDate: END,
  buyerLegalEntityId: NaN,
  sellerLegalEntityId: NaN,
  sellerCompanyId: NaN,
  sellerBankDetailsId: NaN,
  sellerSignerId: NaN,
  managerId: NaN,
  printFormShowMediaLink: false,
  printFormShowDateViewState: INVOICE_PRINT_NOT_SHOW_DATE
};
type Props = {
  open: boolean;
  supplierCompanyId?: number;
  ourCompanyId: number;
  onSubmit: (data: FormData) => Promise<void>;
  onClose: () => void;
  isCompanyPageMode: boolean
};

export function AddSupplierInvoiceDialog(props: Props){
  const {
    open,
    onSubmit,
    onClose,
    supplierCompanyId,
    ourCompanyId,
    isCompanyPageMode = true
  } = props;

  return (
    <FormDialog
      renderForm={() => <FormFields
        supplierCompanyId={isCompanyPageMode ? supplierCompanyId : undefined}
        ourCompanyId={ourCompanyId}
      />}
      renderTitle="Внести счет поставщика"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      validate={formTools.validate}
      validateOnChange={true}
      initialValues={initialValues}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    />
  );
}

const FormFields = ({ supplierCompanyId, ourCompanyId }: { supplierCompanyId?: number, ourCompanyId: number; }) => {
  const { values } = useFormikContext<FormData>();

  const sellerLegalEntityId = (values as any)['sellerLegalEntityId'] ?? undefined;
  const { addDialogOpen, setAddDialogOpen, handleAddBankDetails, collection } = useBankDetailsDialog(sellerLegalEntityId);
  const { openSignerDialog, setOpenSignerDialog, handleAddSigner, collection: signerCollection } = useSignerDialog(sellerLegalEntityId);

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
      <Field
        name='invoiceNumber'
        component={TextField}
        label='Номер'
        required
      />
      <Stack direction="row" spacing={2}>
        <Field
          name='startDate'
          label='Дата выставления'
          component={DatePickerField}
          required
        />
        <Field
          name='endDate'
          label='Дата окончания'
          component={DatePickerField}
          required
        />
      </Stack>
      <Field
        label='Наше юрлицо'
        disableInactive={true}
        component={LegalEntityPicker}
        name='buyerLegalEntityId'
        companyIdFilter={ourCompanyId}
        entityRole={EntityRoles.INVOICE_ACCEPTOR}
        required
      />
      <Field
        label='Юрлицо поставщика'
        disableInactive={true}
        component={LegalEntityPicker}
        name='sellerLegalEntityId'
        companyIdFilter={supplierCompanyId}
        required
      />
      {
        // если счет вносится вне компании поставщика, то выбрать компанию селектом
        !supplierCompanyId && <Field
          label='Фирма поставщика'
          component={CompanyPickerField}
          name='sellerCompanyId'
          legalEntityIdFilter={sellerLegalEntityId}
          required
        />
      }
      <ExternalPickerContainer disabled = {!sellerLegalEntityId}
        onClick={()=> { setAddDialogOpen(true) }}>
        <Field
          label='Банковские реквизиты поставщика'
          name='sellerBankDetailsId'
          component={BankDetailsPicker}
          legalEntityId={sellerLegalEntityId}
          collection={collection}
          required
        />
      </ExternalPickerContainer>
      <ExternalPickerContainer disabled = {!sellerLegalEntityId}
        onClick = {() => setOpenSignerDialog(true)}
      >
        <Field
          label='Подписант поставщика'
          name='sellerSignerId'
          component={SignerPicker}
          legalEntityId={sellerLegalEntityId}
          collection = {signerCollection}
          required
        />
      </ExternalPickerContainer>
      <Field
        name='managerId'
        label='Менеджер'
        component={UserPickerField}
        includeUser
        required
      />
      <BankDetailsDialog
        open={addDialogOpen}
        title='Добавить банковские реквизиты'
        onClose={() => setAddDialogOpen(false)}
        submit={handleAddBankDetails} />
      <SignerDialog
        onClose={()=> setOpenSignerDialog(false)}
        open={openSignerDialog}
        submit={handleAddSigner}
        title={'Добавить подписанта'}/>
    </Stack>);
};
