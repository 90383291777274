import { Box, Stack, TableCell, TableRow } from '@mui/material';
import * as React from 'react';

import { ButtonLink } from 'shared/ui/link-base';
import { Heading } from 'shared/ui/list-page-heading';
import { GridLayout } from 'shared/ui/grid-layout';
import { usePagingParams } from 'shared/hooks/paging-params';
import { usePayOrdersExportHistory } from 'entities/pay-orders/export/pay-orders-export.hook';
import { PayOrdersExportHistoryDto } from '@sr/dto';
import { formatDate } from 'utils/date-format-helpers';
import ManagerSpan from 'shared/ui/manager-span';
import { PayOrdersExportReport } from 'entities/pay-orders/export/pay-orders-export-report';

const columns = ['Отчет', 'Экспортировал', 'Дата'];

export const PayOrderExportHistoryPage = () => {
  const [paging, setPaging] = usePagingParams();
  const payOrdersExportHistory = usePayOrdersExportHistory(paging);
  return (<>
    <Heading
      sx={{ my:2 }}
      title='История экспорта платежных поручений'
      actions={
        <Stack direction="row" spacing={2}>
          <ButtonLink
            to="/accounting"
          >
            Все платежки
          </ButtonLink>
          <ButtonLink
            to="/accounting/export"
          >
            Новый экспорт
          </ButtonLink>
        </Stack>
      }
    />
    <GridLayout
      noItemsText={'Еще ничего не экспортировали'}
      columns={columns}
      items={payOrdersExportHistory.items.items}
      isLoading={payOrdersExportHistory.isLoading}
      error={payOrdersExportHistory.error}
      pagingData={paging}
      onChangePaging={setPaging}
      totalItemsCount={payOrdersExportHistory.items.totalItemsCount}
      itemRender={row => <ExportHistoryRow row={row} key={row.id} />}
    />
  </>
  );
};

const ExportHistoryRow = ({ row }: {row: PayOrdersExportHistoryDto})=>{
  return (
    <TableRow
      key={row.id}
    >
      <TableCell>
        {row.exportReport.map((report, idx) =>
          <Box sx={{ my: 1 }} key={idx}>
            <PayOrdersExportReport report={report} />
          </Box>)}
      </TableCell>
      <TableCell><ManagerSpan person={row.createdBy} /></TableCell>
      <TableCell>{formatDate(row.createdAt)}</TableCell>
    </TableRow>
  );
};
