import { Box, Button, Stack } from '@mui/material';
import { PayOrderFormFields } from 'components/PayOrders/pay-order.form-fields';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePayOrder } from 'hooks/payorders.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { formTools, FormData } from 'components/PayOrders/pay-order.form-tools';
import { HeaderText } from 'shared/ui/header-text';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { PageBreadCrumbs } from 'shared/ui/page-breadcrumbs';
import { useIdParam } from 'utils/url-paramters.hook';
import { useRef } from 'react';
import { useSnack } from 'shared/ui/snack';
import Error404 from 'pages/404';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { CardPaper } from 'shared/ui/card-paper';
import { useNavigate } from 'react-router-dom';

const PayOrderDetailsPage = () => {
  useTitleHook('Платежное поручение');
  const payOrderId = useIdParam('payOrderId');
  const { item, updateItem, loading, deleteItem } = usePayOrder(payOrderId);
  const { showSuccess, showError, showWarning } = useSnack();
  const formikRef = useRef<FormikProps<FormData>>(null);
  const showConfirm = useConfirmDialog();
  const navigate = useNavigate();

  if (loading)
    return <LoadingBanner/>;
  if(!item)
    return <Error404/>;
  
  const saveData = async (values: FormData, { setSubmitting, setErrors, validateForm }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    const errors = await validateForm(values);
    if (errors) 
      setErrors(errors);
    
    try {
      updateItem(formTools.toUpdateDto(item.id, values));
      showSuccess('Данные обновлены');
    } catch (error) {
      showError(`Ошибка обновления платежного поручения ${error}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      await showConfirm({ content: 'Вы уверены, что хотите удалить платежное поручение?' });
      await deleteItem();
      showSuccess('Платежное поручение удалено');
      navigate('/accounting');
    } catch (error) {
      showError(`Ошибка удаления платежного поручения: ${error}`);
    }
  };

  const handleSave = () => {
    if (!formikRef.current) {
      showError('Что-то пошло не так');
      return;
    }
    if(formikRef.current.dirty)
      formikRef.current.submitForm();
    else
      showWarning('Измените хотя бы одно поле');
  };
  return (
    <>
      <PageBreadCrumbs links={[
        { text: 'Бухгалтерия', to: '/accounting' },
        { text: `Платежное поручение № ${item.documentNumber}` },
      ]} />

      <PageHeader
        title={
          <HeaderText>
            <Stack direction='row' alignItems='center' sx={{ whiteSpace: 'pre' }}>
              Платежное поручение <HeaderText.Stressed>{item.documentNumber}</HeaderText.Stressed>
            </Stack>
          </HeaderText>
        }
        subHeader={
          <Stack direction='row' spacing={2} ml={2}>
            <Button variant='contained' color='error' onClick={handleDelete}>удалить</Button>
            <Button variant='contained' onClick={handleSave} disabled={loading}>сохранить</Button>
          </Stack>
        }
      />
        
      <Box maxWidth={600}>
        <CardPaper sx={{ padding: 2 }}>

          <Formik
            initialValues={formTools.fromDto(item)}
            validate={formTools.validate}
            onSubmit={saveData}
            innerRef={formikRef}
            enableReinitialize >
            {(formikProps) => {
              return(
                <Form>
                  <PayOrderFormFields {...formikProps} isNew={false}/>
                </Form>
              );
            }}
          </Formik>
        </CardPaper>
      </Box>
    </>
  );
};


export default PayOrderDetailsPage;
