/**
 * Omits the specified keys from an object and returns a new object without those keys.
 *
 * @param {T} object - The original object from which keys need to be omitted.
 * @param {K[]} keysToOmit - An array of keys to be omitted from the object.
 *
 * @returns {Omit<T, K>} A new object with the specified keys omitted.
 *
 * @template T - The type of the input object.
 * @template K - The type of the keys to be omitted.
 */
export const omit = <T extends object, K extends keyof T>(object: T, keysToOmit: K[]): Omit<T, K> => {
  const result: Partial<T> = {};

  (Object.keys(object) as K[]).forEach((key) => {
    if (!keysToOmit.includes(key)) {
      result[key] = object[key];
    }
  });

  return result as Omit<T, K>;
};
