import * as React from 'react';
import { useRef } from 'react';
import { Box, Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useSnack } from 'shared/ui/snack';
import { Heading } from 'shared/ui/list-page-heading';
import { useActionCreators, useStateSelector } from 'shared/store';

import { UserPicker } from 'features/user/user-picker/user-picker';
import { SalaryEntryForm } from 'entities/salary/salary-entry-dialog/salary-entry.form';
import { SalaryEntriesGrid } from 'entities/salary/salary-entries/salary-entries-grid';
import { setUserSalary } from 'entities/salary/salary-user-picker.slice';
import { salaryDialogActions } from 'entities/salary/salary-entry-dialog/salary-entry.slice';
import { PrepaymentDto } from '@sr/dto';
import { PrepaymentDialogForm } from '../../entities/prepayment/prepayment-dialog/prepayment-dialog.form';
import { prepaymentDialogActions } from '../../entities/prepayment/prepayment-dialog/prepayment-dialog.slice';
import { fetchPrepayment } from '../../entities/prepayment/prepayment.api';

export const SalaryEntries = () => {
  const selectedPeriod = useStateSelector((state) => state.salarySelectedPeriod.value);
  const selectedUserId = useStateSelector((state) => state.salaryUserSelect.value);
  const { setUser } = useActionCreators(setUserSalary);

  const prepaymentToEdit = useRef<PrepaymentDto | null>(null);

  const { showError, showSuccess } = useSnack();
  const { openDialog } = useActionCreators(salaryDialogActions);
  const { openDialog: openPrepaymentDialog } = useActionCreators(prepaymentDialogActions);

  const handleUserChange = (value: number | string) => {
    if (typeof value === 'number') {
      setUser(value)
    }
  };

  const handleAdd = () => {
    prepaymentToEdit.current = null
    openPrepaymentDialog()
  }

  const handleEdit = (salaryEntryId: number) => {
    fetchPrepayment(salaryEntryId).then(p => {
      prepaymentToEdit.current = p
      openPrepaymentDialog()
    })
  }


  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title="Начисления/Удержания"
        actions={
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => openDialog()}
            >
              Добавить запись
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleAdd()}
            >
              Внести аванс
            </Button>
          </Stack>} />
      <Box sx={{ minWidth: 200, maxWidth: 308, mb: 1 }}>
        <UserPicker value={selectedUserId} onChange={handleUserChange} label="Менеджер" size="small" />
      </Box>
      <SalaryEntryForm />

      <PrepaymentDialogForm prepayment={prepaymentToEdit} />

      {
        typeof selectedUserId === 'number'
          ? <SalaryEntriesGrid year={selectedPeriod.year} month={selectedPeriod.month} userId={selectedUserId}
                               onEditClick={handleEdit} />
          : <Box sx={{ pt: 2 }}>Выберите менеджера для просмотра заработной платы</Box>
      }
    </>
  );
};
