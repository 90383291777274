import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { invoiceReportApi } from './invoice-report.api';

export const useInvoiceReportRows = (invoiceId: number) => {
  const { data, isLoading, error } = invoiceReportApi.useGetInvoiceRowsQuery({
    invoiceId,
  });
  return {
    report: data,
    error: extractNestJsErrorMessage(error),
    isLoading,
  };
};
