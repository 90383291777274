import { useState } from 'react';
import { Button, ListItem, ListItemText, IconButton, Box } from '@mui/material';
import { useSnack } from 'shared/ui/snack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormData } from 'components/Companies/Signers/signers.form';
import { SignerDto } from '@sr/dto';
import MenuParams from 'shared/ui/menu-params';
import { useSignersList } from 'hooks/signers.hook';
import { SignerDialog } from 'components/Companies/Signers/signer-dialog';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { ListLayout } from 'shared/ui/list-layout';
import { useSignerDialog } from '../../../shared/providers/legal-entities.provider';


type Props = {
  legalEntityId: number;
};

export const LegalEntitySigners = (props: Props) => {
  const { legalEntityId } = props;

  const { showError, showSuccess } = useSnack();
  const showConfirm = useConfirmDialog();
  const { openSignerDialog, setOpenSignerDialog, handleAddSigner, collection } = useSignerDialog(legalEntityId);

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [editSigner, setEditSigner] = useState<SignerDto | undefined>(undefined);

  const { updateSigner, removeSigner } = useSignersList(legalEntityId);


  const { openMenu, menuData } = useMenu<SignerDto>({
    deleteHandler: (target: SignerDto) => {
      showConfirm({
        content: 'Удалить данные подписанта?'
      })
        .then(() => {
          return removeSigner(target.id);
        })
        .then(() => showSuccess('Подписант успешно удален'))
        .catch(e => showError(`Ошибка удаления подписанта: ${e.message}`));
    },
    editHandler: (target: SignerDto) => {
      setEditSigner(target);
      setEditDialogOpen(true);
    }
  });

  const handleUpdateSigner = async (formData: FormData) => {
    if (!editSigner) return;

    return updateSigner(editSigner.id, formData)
      .then(() => {
        closeEditDialog();
        showSuccess('Подписант успешно обновлен');
      })
      .catch(e => showError(`Ошибка обновления подписанта: ${e.message}`));
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setEditSigner(undefined);
  };

  return (
    <>
      <ListLayout<SignerDto>
        items={collection}
        itemRender={(item) => <SignerItem key={item.id} signer={item} onMenuClick={openMenu} />}
        noItemsText='Список подписантов пуст'
      />

      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          autoFocus
          onClick={() => setOpenSignerDialog(true)}
        >
          Добавить
        </Button>
      </Box>

      <SignerDialog
        open={openSignerDialog}
        title='Добавить подписанта'
        onClose={() => setOpenSignerDialog(false)}
        submit={handleAddSigner}
      />

      <SignerDialog
        open={editDialogOpen}
        title='Редактировать подписанта'
        values={editSigner}
        onClose={closeEditDialog}
        submit={handleUpdateSigner} />

      <RowMenu menuData={menuData} />
    </>
  );
};



type ItemProps = {
  signer: SignerDto;
  onMenuClick: (menuParams: MenuParams<SignerDto>) => void;
};

const SignerItem = (props: ItemProps) => {
  const {
    onMenuClick,
    signer
  } = props;

  const { titleForm1, positionForm1 } = signer;

  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" onClick={(e) => onMenuClick({ anchor: e.currentTarget, target: signer })}>
          <MoreVertIcon />
        </IconButton>}
    >
      <ListItemText
        primary={`${titleForm1}`}
        secondary={positionForm1}
      />
    </ListItem>);
};
