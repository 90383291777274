import { InvoiceCanceledStatusDto } from '@sr/dto';
import { invoiceApi } from 'entities/invoice/api-slice';


const injectedApi = invoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceCanceledStatus: builder.query<InvoiceCanceledStatusDto, number>({
      query: (invoiceId: number) => `invoices/${invoiceId}/canceled-status`,
    }),
    updateInvoiceCanceledStatus: builder.mutation<{}, { invoiceId: number }>({
      query: ({ invoiceId }) => ({
        url: `invoices/${invoiceId}/canceled-status`,
        body: {},
        method: 'PUT',
      }),
    }),
  }),
});

export const invoiceCanceledStatusApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['invoiceCanceledStatus', 'invoicesList'],
  endpoints: {
    getInvoiceCanceledStatus: {
      providesTags: (result, _, invoiceId) => (
        result ? [{ type: 'invoiceCanceledStatus', id: invoiceId }] : ['invoiceCanceledStatus']
      ),
    },
    updateInvoiceCanceledStatus: {
      invalidatesTags: (res, error, query) => (
        res && !error ? [{
          type: 'invoiceCanceledStatus',
          id: query.invoiceId,
        }, 'invoicesList'] : ['invoiceCanceledStatus']
      ),
    },
  },
});
