import {
  BankAccountDto,
  BankAccountType,
  CreateBankAccountDto,
  UpdateBankAccountDto,
} from "@sr/dto";
import { FormTools, VALIDATION_REQUIRED_FIELD } from "utils/form-tools";
import { FormikErrors } from "formik";

export type FormData = {
  title: string;
  type: number | "";
  isActive: boolean | "";
  legalEntityId?: number;
  bankDetailsId?: number;
};

export const formTools: FormTools<
  BankAccountDto,
  CreateBankAccountDto,
  UpdateBankAccountDto,
  FormData
> = {
  toUpdateDto: (id, formData) => ({
    id: id,
    title: formData.title,
    type: formData.type as BankAccountType,
    isActive: !!formData.isActive,
    legalEntityId: formData.legalEntityId,
    bankDetailsId: formData.bankDetailsId,
  }),
  toCreateDto: (formData) => ({
    title: formData.title,
    type: formData.type as BankAccountType,
    legalEntityId: formData.legalEntityId,
    bankDetailsId: formData.bankDetailsId,
    isActive: !!formData.isActive,
  }),
  fromDto: (dto) => ({
    title: dto.title,
    isActive: dto.isActive,
    type: dto.type,
    legalEntityId: dto.legalEntityId,
    bankDetailsId: dto.bankDetailsId,
  }),
  validate: (values: FormData): FormikErrors<FormData> => {
    let validationResult: FormikErrors<FormData> = {};

    if (!values.title) validationResult.title = VALIDATION_REQUIRED_FIELD;

    return validationResult;
  },
  emptyValues: () => ({
    isActive: "",
    type: 1,
    title: "",
    legalEntityId: undefined,
    bankDetailsId: undefined,
  }),
};
