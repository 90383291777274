import { createContext, ReactNode, useState, Dispatch, SetStateAction, useContext } from 'react';
import { FormData as BankDetailsFormData } from '../../components/Companies/BankDetails/bank-details.form';
import { FormData as SignerFormData } from '../../components/Companies/Signers/signers.form';
import { useBankDetailsList } from '../../hooks/bank-details.hook';
import { useSnack } from '../ui/snack';
import { useSignersList } from '../../hooks/signers.hook';

type InitialValues = Readonly<{
  openBankDetailsDialog: boolean;
  setOpenBankDetailsDialog: Dispatch<SetStateAction<boolean>>
  openSignerDialog: boolean
  setOpenSignerDialog: Dispatch<SetStateAction<boolean>>
}>
const initialValues = {
  openBankDetailsDialog: false,
  setOpenBankDetailsDialog: () => {},
  openSignerDialog: false,
  setOpenSignerDialog: () => {},
};

const LegalEntitiesContext = createContext<InitialValues>(initialValues);

export const LegalEntitiesProvider = ({ children }: {children: ReactNode}) => {
  const [openBankDetailsDialog, setOpenBankDetailsDialog] = useState(false);
  const [openSignerDialog, setOpenSignerDialog] = useState(false);

  return (
    <LegalEntitiesContext.Provider value={{ openBankDetailsDialog, setOpenBankDetailsDialog, openSignerDialog, setOpenSignerDialog }}>
      {children}
    </LegalEntitiesContext.Provider>
  );
};

export const useBankDetailsDialog = (legalEntityId: number | string | null) => {
  const context = useContext(LegalEntitiesContext);
  if (context === undefined) {
    throw new Error('useBankDetailsDialog must be used within provider');
  }

  const { collection, addBankDetails } = useBankDetailsList(legalEntityId as number);
  const { showSuccess, showError } = useSnack();

  const handleAddBankDetails = async (formData: BankDetailsFormData) => {
    try {
      await addBankDetails(formData);
      collection.reload();
      context.setOpenBankDetailsDialog(false);
      showSuccess('Реквизиты успешно добавлены');
    } catch (err ) {
      showError('Ошибка добавления реквезитов');
      context.setOpenBankDetailsDialog(false);
    }
  };

  return {
    addDialogOpen: context.openBankDetailsDialog,
    setAddDialogOpen: context.setOpenBankDetailsDialog,
    handleAddBankDetails, collection,
  };
};

export const useSignerDialog = (legalEntityId: number | string | null) => {
  const context = useContext(LegalEntitiesContext);
  if (context === undefined) {
    throw new Error('useSignerDialog must be used provider');
  }

  const { collection, addSigner } = useSignersList(legalEntityId as number);
  const { showSuccess, showError } = useSnack();

  const handleAddSigner = async (formData: SignerFormData) => {
    try {
      await addSigner(formData);
      collection.reload();
      context.setOpenSignerDialog(false);
      showSuccess('Подписант успешно добавлен');
    } catch (err ) {
      showError('Ошибка добавления подписанта');
      context.setOpenSignerDialog(false);
    }
  };

  return {
    openSignerDialog: context.openSignerDialog,
    setOpenSignerDialog: context.setOpenSignerDialog,
    handleAddSigner, collection,
  };
};
