import { Field, FormikProvider, useFormik } from 'formik';
import { YearPickerField } from 'shared/ui/formik-fields/year-picker';
import { useSearchParams } from 'react-router-dom';
import {
  formValuesFromUrlSearchParams,
  mergeFormValuesVsCurrentSearchParams,
} from 'utils/url-search-params.helper';
import { useEffect } from 'react';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';
import { BaseUrlParamsFilterProps } from './base-url-params-filter';

type FormValues =  Record<string, string | null>

type Props = BaseUrlParamsFilterProps & {
}

export const YearUrlParamsFilter = ({ queryParamName }: Props) => {
  // todo: migrate to base-url-params-filter. For this we should make the field
  //  "clearable" and change '' to null as default value
  const defaultValues: FormValues = {
    [queryParamName]: '',
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const formik = useFormik<FormValues>({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultValues),
    onSubmit: () => {},
    validateOnChange: true,
    enableReinitialize: true,
  });

  const formValue: string | null = formik.values[queryParamName] ?? null;

  useEffect(() => {
    if (!formik.isValid) return ;

    setSearchParams(prev => {
      const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultValues);
      if (Object.keys(formik.touched).length || formik.dirty) {
        // раз пользователь обновил фильтр, сбрасываем страницу на 0
        params.delete(PAGE_FIELD_NAME);
      }
      return params;
    });
  }, [formValue]);

  return (
    <FormikProvider value={formik}>
      <Field
        fullWidth
        name={queryParamName}
        label="Год"
        component={YearPickerField}
      />
    </FormikProvider>
  );
};
