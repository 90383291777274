import { plainToInstance, Type } from 'class-transformer';
import { IsDate, IsIn, IsNotEmpty, IsNumber } from 'class-validator';
import { DownloadFileDto } from './download-file.dto';
import { InvoiceRowDto } from './invoice-row.dto';
import { InvoiceDto } from './invoice.dto';
import { UserDto } from './user.dto';
import { PURCHASE_STATUSES } from './enums/purchase-statuses';

export class PurchaseInfoDto {
  @IsNumber()
  id: number;

  @IsNumber()
  paymentAmount: number;

  @IsDate()
  paymentDate: Date;

  @Type(() => DownloadFileDto)
  invoiceScan: DownloadFileDto | null;

  @Type(() => DownloadFileDto)
  bankDetailsScan: DownloadFileDto | null;

  @Type(() => DownloadFileDto)
  wccScan: DownloadFileDto | null;

  @Type(() => PurchaseStatusDto)
  status: PurchaseStatusDto;

  @Type(() => InvoiceRowDto)
  clientInvoiceRow: InvoiceRowDto;

  @Type(() => InvoiceDto)
  clientInvoice: InvoiceDto;

  @Type(() => InvoiceRowDto)
  supplierInvoiceRow: InvoiceRowDto;

  @Type(() => InvoiceDto)
  supplierInvoice: InvoiceDto;

  @Type(() => UserDto)
  manager: UserDto;

  @IsDate()
  createdAt: Date;

  static fromPlain(obj: any) {
    return plainToInstance<PurchaseInfoDto, any>(PurchaseInfoDto, obj, { enableImplicitConversion: true });
  }
}

export class PurchaseDto extends PurchaseInfoDto {
}

export class CreatePurchaseDto {
  @IsNumber()
  @IsNotEmpty()
  clientInvoiceRowId: number;
}

export class UnlinkSupplierDto {
  @IsNumber()
  id: number;
}

export class LinkSupplierDto {
  @IsNumber()
  purchaseId: number;

  @IsNumber()
  invoiceRowId: number;
}

// export class UpdatePurchaseDto {
//   @IsNumber()
//   @Type()
//   id: number;

//   @IsNumber()
//   @Type()
//   paymentAmount: number;

//   @IsDate()
//   @Type(() => Date)
//   paymentDate: Date;

//   @IsNumber()
//   @Type(() => Number)
//   statusId: number;

//   // File - прикрепить новый файл
//   // null - удалить прикрепленный файл
//   // undefined - ничего не делать

//   invoiceScan?: File | null;
//   // bankDetailsScan?: File | null;
//   // wccScan?: File | null;

// }

export class CreatePayOrderFromPurchaseDto {
  amount: number;
}

export class PurchaseStatusDto {
  id: number;
  label: string;
}

export class PurchaseStatusCheckDto {
  @IsIn(PURCHASE_STATUSES.map(x => x.id))
  status: number;
  ids: number[];
}
