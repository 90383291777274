import { DownloadFileDto, Identifiable, isFile } from '@sr/dto';
import { FormikErrors } from 'formik';

export type FormTools<TDto extends Identifiable, TCreateDto, TUpdateDto, TFormData> = {
  toUpdateDto: (id: number, formData: TFormData) => TUpdateDto,
  toCreateDto: (formData: TFormData) => TCreateDto,
  fromDto: (item: TDto) => TFormData,
  validate: (values: TFormData) => FormikErrors<TFormData>,
  emptyValues: () => TFormData
}

export type FormDataFileField = File | DownloadFileDto | null;

export const VALIDATION_REQUIRED_FIELD = 'Обязательное поле';

/**
 *  @param  fieldValue значение формы. Если DownloadFileDto, то файл сохранен уже в базе
 *  пока пользователь ничего не выбрал возвращаем undefined и ничего не меняем
 *  @return Blob
 *          File - прикреплен новый файл для загрузки
 *          null - удалить файл
 *          undefined - начальное состояние формы, сервер ничего не будет менять
 * @param fieldValue
 */
export const fileFieldToDto = (fieldValue: FormDataFileField): File | null | undefined => {
  return fieldValue === null
    ? null
    : isFile(fieldValue)
      ? fieldValue
      : undefined;
};
