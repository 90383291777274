import {plainToInstance, Type} from "class-transformer";
import {
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsNumberString,
    IsOptional,
    IsString,
    MaxLength,
    MinLength
} from "class-validator";
import {Identifiable} from "./identifiable";
import { StringifiedBoolean } from "./publishing-data.dto";
import { LegalEntityTypeDto } from "./legal-entity-type.dto";


export class CreateLegalEntityDto {
    @IsNotEmpty()
    name: string;

    @IsNumberString({ no_symbols: true })
    inn: string;

    @IsNumberString({ no_symbols: true })
    kpp: string;

    // @Type(() => LegalEntityTypeDto)
    // legalEntityTypeId: LegalEntityTypeDto;
    @IsNumber()
    legalEntityTypeId: number;

    @IsString()
    address: string;

    @IsString()
    postAddress: string;

    @IsOptional()
    @IsNumber()
    vat?: number;
}

export class LegalEntityDto extends CreateLegalEntityDto {
    id: number;

    @Type(() => LegalEntityTypeDto)
    legalEntityType: LegalEntityTypeDto;

    isActive: boolean;
    isOurLegalEntity?: boolean;
    canIssueInvoice?: boolean;
    canAcceptInvoice?: boolean;
}

export class UpdateLegalEntityDto extends CreateLegalEntityDto {
    id: number;
}

export class LegalEntityBaseInfoDto {
    id: number;
    name: string;
    @Type(() => LegalEntityTypeDto)
    legalEntityType: LegalEntityTypeDto;
    inn: string;
    kpp: string;
    isActive: boolean;
    isOurLegalEntity?: boolean;
    canIssueInvoice?: boolean;
    canAcceptInvoice?: boolean;

    static fromPlain(obj: any) { return plainToInstance<LegalEntityBaseInfoDto, any>(LegalEntityBaseInfoDto, obj); }
}

export class LegalEntityOurDataDto extends Identifiable{
    invoicePrefix: string;
    isClientInvoicesEnabled: boolean;
    isSupplierInvoicesEnabled: boolean;
    stamp?: File | null | undefined;
}

export class LegalEntityOurDataMultipartFormUpdateDto extends Identifiable {
    @IsString()
    @MaxLength(8)
    @IsNotEmpty()
    invoicePrefix: string;

    @IsString()
    @IsEnum(StringifiedBoolean)
    isClientInvoicesEnabled: StringifiedBoolean;

    @IsString()
    @IsEnum(StringifiedBoolean)
    isSupplierInvoicesEnabled: StringifiedBoolean;

    stamp?: File | null | undefined;
}
export type LegalEntityOurDataMultipartFormCreateDto  = Omit<LegalEntityOurDataMultipartFormUpdateDto, 'id'>

export class LegalEntityActiveStatusDto {
    isActive: boolean;
}

export class LegalEntityFilterDto {
    @IsString()
    @IsOptional()
    @MinLength(2)
    @MaxLength(20)
    search?: string

    @IsNumber()
    @IsOptional()
    companyId?: number;
}
