import {
  BOOLEAN_LIKE_STRINGS,
  DATE_DTO_REGEX,
  queryStringToBool,
  queryStringToDate,
  date2QueryString, FilterFormValues,
} from '@sr/dto';

export type SerializableValues = string | number | Date | boolean| null

export const formValuesFromUrlSearchParams =
  <FormValues extends Record<string, SerializableValues>>
  (params: URLSearchParams, defaultValues: FormValues): FormValues => {
    let output = { ...defaultValues };
    Object.keys(output).forEach((key) => {
      const value = params.get(key);
      if (!value) return;
      const defaultValue = defaultValues[key];
      if (!isNaN(Number(value))) {
        output = { ...output, [key]: Number(value) };
        return;
      }
      if (typeof defaultValue === 'boolean' && BOOLEAN_LIKE_STRINGS.includes(value)){
        output = { ...output, [key]: queryStringToBool(value) };
      }
      if (DATE_DTO_REGEX.test(value)) {
        output = { ...output, [key]: queryStringToDate(value) };
      }
      if (typeof defaultValue === 'string' && !['null', 'undefined', 'NaN'].includes(String(value))) {
        output = { ...output, [key]: value };
      }
    });
    return output;
  };

export const urlSearchParamsFromFormValues =
  <FormValues extends Record<string, SerializableValues>>
  (formValues: FormValues, defaultValues: FormValues): URLSearchParams => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(formValues)) {
      if (defaultValues[key] === value) continue;
      if (value instanceof Date) {
        params.append(key, date2QueryString(value)); // eg. 11.03.2024
        continue;
      }
      params.append(key, String(value));
    }
    return params;
  };

export const getRestSearchParams = (searchParams: URLSearchParams, defaultValues: FilterFormValues<any>): URLSearchParams => {
  const restSearchParams = new URLSearchParams();
  for (const [key, value] of searchParams.entries()){
    if (!Object.keys(defaultValues).includes(key)) {
      restSearchParams.append(key, value);
    }
  }
  return restSearchParams;
};

export const mergeFormValuesVsCurrentSearchParams = <T extends Record<string, SerializableValues>>(
  current: URLSearchParams, formValues: FilterFormValues<T>, defaultValues: FilterFormValues<T>
): URLSearchParams => {
  const restSearchParams = getRestSearchParams(current, defaultValues);
  const formSearchParams = urlSearchParamsFromFormValues(formValues, defaultValues);
  return new URLSearchParams([
    ...formSearchParams.entries(),
    ...restSearchParams.entries(),
  ]);
};

