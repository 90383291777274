import { Field } from 'formik';
import { BaseUrlParamsFilter, BaseUrlParamsFilterProps } from './base-url-params-filter';
import { BankAccountPicker } from 'shared/ui/formik-fields/bank-account-picker';

export const BankAccountUrlParamsFilter = ({ queryParamName, label= 'Расчетный счет', size = 'medium' }: BaseUrlParamsFilterProps) => {
  return (
    <BaseUrlParamsFilter queryParamName={queryParamName}>
      <Field
        fullWidth
        name={queryParamName}
        label={label}
        size={size}
        component={BankAccountPicker}
      />
    </BaseUrlParamsFilter>
  );
};
