import { useSearchParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Stack,
} from '@mui/material';
import PayOrdersList from 'components/PayOrders/pay-orders-list';
import { useState } from 'react';
import { usePayOrdersExport, usePayOrdersExportCollection } from 'entities/pay-orders/export/pay-orders-export.hook';
import { downloadFile } from 'utils/download-file';
import { PayOrdersExportFilter } from 'entities/pay-orders/export/pay-orders-export.filter';
import { IPagingData, PayOrdersExportDto } from '@sr/dto';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { ButtonLink } from 'shared/ui/link-base';
import { Heading } from 'shared/ui/list-page-heading';
import * as React from 'react';
import { PayOrdersExportReport } from 'entities/pay-orders/export/pay-orders-export-report';

export const PayOrdersExportPage = ()=>{
  const paging: IPagingData = { itemsPerPage:100, page: 0 };
  const [ searchParams ] = useSearchParams();
  const collection = usePayOrdersExportCollection(searchParams);
  const [ selectedPayOrderIds, setSelectedPayOrderIds ] = useState<number[]>([]);
  const { runExport, reports, loading: exportLoading, error: exportError } = usePayOrdersExport();

  const onRowSelect = (payOrderId : number, isSelected : boolean) => {
    if (isSelected) {
      setSelectedPayOrderIds(prev => Array.from(new Set([...prev, payOrderId])));
    } else {
      setSelectedPayOrderIds(prev => prev.filter(item => item !== payOrderId));
    }
  };

  const handleExport = () =>
    runExport(selectedPayOrderIds)
      .then((exportFiles: PayOrdersExportDto[]) => {
        setSelectedPayOrderIds([]);
        for (const report of exportFiles) {
          downloadFile(`/api/files/${report.file.id}`, report.file.fileName);
        }
      }).catch(e=>{
        console.error(e);
      });

  if (exportLoading||collection.isLoading)
    return <LoadingBanner/>;

  if (collection.error || exportError)
    return <>
      <ErrorBanner errorMessage={collection.error ? collection.error: exportError}/>
      <Link
        onClick={(e)=>{ e.preventDefault(); window.location.reload() }}
        sx={{ cursor: 'pointer' }}>
        Назад к экспорту
      </Link>
    </>;

  if (reports)
    return (<>
      { reports.map(
        (reportLine, idx) =>
          <Box sx={{ my: 1 }} key={idx}>
            <PayOrdersExportReport report={reportLine}/>
          </Box>)
      }
      <Link
        onClick={(e)=>{ e.preventDefault(); window.location.reload() }}
        sx={{ cursor: 'pointer' }}>
          Назад к экспорту
      </Link>
    </>);

  return (<>
    <Heading
      sx={{ my:2 }}
      title='Экспорт платежных поручений'
      actions={
        <Stack direction="row" spacing={2}>
          <ButtonLink
            to="/accounting"
          >
          Все платежки
          </ButtonLink>
          <ButtonLink
            to="/accounting/export/history"
          >
          История экспорта
          </ButtonLink>
        </Stack>
      }/>
    <PayOrdersExportFilter/>
    { collection.collection.totalItemsCount > collection.collection.items.length &&
      <Box sx={{ width: '100%', mb: 2 }}>
        <Alert severity="warning">
          <AlertTitle>Внимание!</AlertTitle>
          <b>{collection.collection.totalItemsCount}</b> платежек доступно для экспорта по заданному фильтру.
          Только 100 из них будет экспортировано за один раз. После этого можно экспортировать оставшиеся.
        </Alert>
      </Box>
    }
    <PayOrdersList
      collection={collection}
      paging={paging}
      selectedRows={selectedPayOrderIds}
      onRowSelect={onRowSelect}
    />
    <Stack sx={{ mt: 2 }} spacing={1} direction='row'>
      <Button
        onClick={handleExport}
        variant='contained'
      >
        Экспорт
      </Button>

      <ButtonLink
        to="/accounting"
      >
        К списку платежек
      </ButtonLink>
    </Stack>
  </>);
};
