import { TableRow, TableCell, CircularProgress } from '@mui/material';
import { InvoiceTotals } from '@sr/dto';
import PricePair from 'shared/ui/price-pair';
import PriceSpan from 'shared/ui/price-span';

export const TotalsRow = ({ totals }: { totals?: InvoiceTotals | null; }) => {
  return (
    <TableRow>
      <TableCell colSpan={2} />
      <TableCell sx={{ borderTop: '3px black' }} align='right'>Итого:</TableCell>
      <TableCell>{totals ? <PriceSpan price={totals.priceExclVatTotal} /> : <CircularProgress />}</TableCell>
      <TableCell>{
        totals
          ? <PricePair price={totals.priceTotal} priceDiscount={totals.discountPriceTotal} />
          : <CircularProgress />}
      </TableCell>
    </TableRow>);
};
