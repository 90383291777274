import { memo } from 'react';
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  Container,
  Button
} from '@mui/material';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonSpan from 'shared/ui/person-span';
import ManagerSpan from 'shared/ui/manager-span';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { EventDto, EventTaskType } from '@sr/dto';
import { useUpdateEventToggle } from 'features/events/events-actions/event-actions.hook';
import { useSnack } from 'shared/ui/snack';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';


type Props = {
  item: EventDto,
  showCompany?: boolean;
};

const EventItem = (props: Props) => {
  const {
    item: {
      id,
      taskType,
      content,
      manager,
      completed,
      important,
      contactPerson,
      createdDate: createdDateStr,
      dueDate: dueDateStr,
      company },
    showCompany = false,
  } = props;
  const dueDate = dueDateStr ? new Date(dueDateStr) : null;
  const createdDate = new Date(createdDateStr);
  const { showError } = useSnack();

  const updateEventToggle = useUpdateEventToggle();

  const handleImportantClick = async () => {
    await updateEventToggle({ id, completed, important: !important }).catch((e)=>{
      showError('Не получилось: ' + extractNestJsErrorMessage(e));
    });
  };

  const handleDoneClick = async () => {
    await updateEventToggle({ id, completed: !completed, important }).catch((e)=>{
      showError('Не получилось: ' + extractNestJsErrorMessage(e));
    });
  };
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        {showCompany &&
          <Box sx={{
            background: (theme) => theme.palette.grey[300],
            p: 1 }}>
            Фирма: <LinkToCompany company={company}/>
          </Box>}
        <Stack direction="row" alignItems="center" spacing={2} sx={{ p:1 }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              flexShrink: 0,
              display: 'flex',
              borderRadius: 1.5,
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.neutral',
            }}
          >
            {getIcon(taskType)}
          </Box>

          <Box sx={{ flexGrow: 1, minWidth: 160 }}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 0, color: 'text.secondary' }}>
              {manager && <ManagerSpan person={manager} />}
              {contactPerson && <ArrowForwardIosIcon fontSize='small' />}
              {contactPerson && <PersonSpan person={contactPerson} />}
            </Stack>
            <Box sx={{ mt: 1, mb: 1 }} >
              {dueDate &&
                  <Stack direction='row' alignItems='baseline' spacing={1}>
                    <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                      Назначенная дата:
                    </Typography>
                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                      {formatDate(dueDate)}
                    </Typography>
                  </Stack>
              }
            </Box>
          </Box>


          <Stack alignItems="flex-end" sx={{ pr: 1 }}>
            <Stack direction='row' spacing={1}>
              <ToggleButton
                active={completed!}
                activeCaption='выполнено'
                inactiveCaption='выполнить'
                color='primary'
                onClick={handleDoneClick} />
              <ToggleButton
                active={!important}
                activeCaption='важное'
                inactiveCaption='важное'
                color='warning'
                onClick={handleImportantClick} />
            </Stack>
            <Typography variant="caption" sx={{ mt: 0.5, color: 'text.secondary' }}>
              {formatDate(createdDate)}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Container sx={{ p:2 }}>
          {content}
        </Container>
      </Card >
    </>

  );
};

export default memo(EventItem, (prevProps, nextProps)=>
  prevProps.item.id === nextProps.item.id &&
  prevProps.item.important === nextProps.item.important &&
  prevProps.item.completed === nextProps.item.completed
);

type ToggleButtonProps = {
  active: boolean;
  activeCaption: string;
  inactiveCaption: string;
  onClick: () => void;
  color: 'inherit' | 'primary' | 'error' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
};

const ToggleButton = (props: ToggleButtonProps) => {
  const { active, activeCaption, inactiveCaption, color } = props;
  const varaint = !active ? 'contained' : 'outlined';
  const text = active ? activeCaption : inactiveCaption;
  return (
    <Button
      variant={varaint}
      size='small'
      onClick={props.onClick}
      color={color}
    >
      {text}
    </Button>);
};


function getIcon(type: EventTaskType|string) {
  switch (type) {
  case 'call':
    return <ContactPhoneIcon color='primary' />;
  case 'email':
    return <ContactMailIcon color='primary' />;
  case 'meeting':
    return <Diversity3Icon color='primary' /> ;
  }
}
