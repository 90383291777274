import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_BASE_URL, INVOICE_REPORT_BASE_URL } from 'shared/api/api';
import { setAuthHeader } from 'shared/api/rtk-query';
import { InvoiceReportDto, InvoiceRowDto } from '@sr/dto';

export const invoiceReportApi = createApi({
  reducerPath: 'invoice-report',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_BASE_URL}${INVOICE_REPORT_BASE_URL}`,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInvoiceRows: builder.query<InvoiceReportDto, { invoiceId: number }>({
      query: ({ invoiceId }) => ({
        url: `rows/${invoiceId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { invoiceId }) => [
        { type: 'invoice-report-rows', id: invoiceId },
      ],
    }),
  }),
  tagTypes: ['invoice-report-rows'],
});
