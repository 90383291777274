type REVENUE = 1;
type CLIENT_PAYBACK = 2;
type SUPPLIER_PAYMENT = 3;
type SUPPLIER_PAYBACK = 4;
type EMPLOYEE_PAYMENT = 5;
type OTHER_INCOMES = 6;
type OTHER_OUTCOMES = 7;

export type PayOrderGroup =
  | REVENUE
  | CLIENT_PAYBACK
  | SUPPLIER_PAYMENT
  | SUPPLIER_PAYBACK
  | EMPLOYEE_PAYMENT
  | OTHER_INCOMES
  | OTHER_OUTCOMES;

export const POG_REVENUE: REVENUE = 1;
export const POG_CLIENT_PAYBACK: CLIENT_PAYBACK = 2;
export const POG_SUPPLIER_PAYMENT: SUPPLIER_PAYMENT = 3;
export const POG_SUPPLIER_PAYBACK: SUPPLIER_PAYBACK = 4;
export const POG_EMPLOYEE_PAYMENT: EMPLOYEE_PAYMENT = 5;
export const POG_OTHER_INCOMES: OTHER_INCOMES = 6;
export const POG_OTHER_OUTCOMES: OTHER_OUTCOMES = 7;

export const INCOME_STATUSES = [
  POG_REVENUE,
  POG_SUPPLIER_PAYBACK,
  POG_OTHER_INCOMES,
];

export const EXPENSES_STATUSES = [
  POG_CLIENT_PAYBACK,
  POG_SUPPLIER_PAYMENT,
  POG_EMPLOYEE_PAYMENT,
  POG_OTHER_OUTCOMES,
];

export const POG_CLIENT_INVOICE_STATUSES = [
  POG_REVENUE,
  POG_CLIENT_PAYBACK,
  POG_OTHER_INCOMES,
  POG_EMPLOYEE_PAYMENT,
];

export const POG_SUPPLIER_INVOICE_STATUSES = [
  POG_SUPPLIER_PAYMENT,
  POG_SUPPLIER_PAYBACK,
  POG_OTHER_OUTCOMES,
  POG_EMPLOYEE_PAYMENT,
];

export const PO_GROUPS: { [key in PayOrderGroup]: string } = {
  1: 'Выручка',
  2: 'Возврат клиенту',
  3: 'Оплата поставщику',
  4: 'Возврат от поставщика',
  5: 'Выплата сотруднику',
  6: 'Прочие поступления',
  7: 'Прочие списания',
};

export const PO_GROUPS_LIST = Object.keys(PO_GROUPS);

type DRAFT = 1;
type CONFIRMED = 2;
type PAID = 3;
type EXPORTED = 4;

export type PayOrderStatus = DRAFT | CONFIRMED | PAID | EXPORTED;

export const POS_DRAFT: DRAFT = 1;
export const POS_CONFIRMED: CONFIRMED = 2;
export const POS_PAID: PAID = 3;
export const POS_EXPORTED: EXPORTED = 4;

export const POS_STATUSES: { [key in PayOrderStatus]: string } = {
  1: 'Черновик',
  2: 'Подтверждено',
  3: 'Оплачено',
  4: 'Отправлена в банк',
};
