import { LinkType } from '@sr/dto';
import { FormikErrors } from 'formik';
import { ICreateManagerLink } from '@sr/dto';


export interface FormData {
  userId: number | '';
  linkType: LinkType;
  endDate: Date
}

export const toCreateDto = (companyId: number, values: FormData) => {

  const postData: ICreateManagerLink = {
    companyId,
    managerId: Number(values.userId),
    linkType: values.linkType,
    endDate: values.endDate,
  };
  return postData;
};

export const initialValues: FormData = {
  userId: '',
  linkType: 'link',
  //100 days from current date
  endDate: new Date(Date.now() + (1000 * 60 * 60 * 24 * 100))
};

export const validate = (values: FormData) => {
  const errors: FormikErrors<FormData> = {};

  const requiredMsg = 'Поле обязательно';
  if (!values.userId) errors.userId = requiredMsg;

  if (!values.linkType) errors.linkType = requiredMsg;

  if (!values.endDate) {
    errors.endDate = requiredMsg;
  }
  else if (values.endDate < new Date()) {
    errors.endDate = 'Дата не может быть меньше текущей';
  }

  return errors;
};
