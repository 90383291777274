import {
  FormData as PrepaymentFormData,
  formTools as prepaymentFormTools,
  notificationsConfig as prepaymentNotificationsConfig,
} from '../prepayment.form-tools';
import { PrepaymentFormFields } from './prepayment.form-fields';
import { FormDialog } from '../../../shared/ui/form-dialog';
import * as React from 'react';
import { useEffect } from 'react';
import { useCreateOrEditDialog } from '../../../hooks/create-or-edit-dialog.hook';
import { CreatePrepaymentDto, PrepaymentDto, UpdatePrepaymentDto } from '@sr/dto';
import { useActionCreators, useStateSelector } from '../../../shared/store';
import { useAddPrepayment, useUpdatePrepayment } from '../prepayment.hook';
import { prepaymentDialogActions } from './prepayment-dialog.slice';

interface Props {
  prepayment: React.MutableRefObject<PrepaymentDto | null>;
}

export const PrepaymentDialogForm = (props: Props) => {
  const { prepayment } = props;

  const isDialogOpened = useStateSelector(state => state.ui.prepaymentDialog.isPrepaymentDialogOpened);
  const { closeDialog: closePrepaymentDialog } = useActionCreators(prepaymentDialogActions);

  const addPrepayment = useAddPrepayment();
  const updatePrepayment = useUpdatePrepayment();

  const {
    dialogProps,
    openToAdd,
    openToEdit,
    itemToEdit,
  } = useCreateOrEditDialog<PrepaymentDto & { id: number },
    CreatePrepaymentDto,
    UpdatePrepaymentDto,
    PrepaymentFormData>({
    formTools: prepaymentFormTools,
    addHandler: async (dto) => {
      await addPrepayment(dto);
    },
    updateHandler: async (dto) => {
      console.log(dto,'dto to update')
      await updatePrepayment(dto);
    },
    notificationsConfig: prepaymentNotificationsConfig,
  });

  useEffect( () => {
    if (isDialogOpened) {
      if (prepayment) {
        openToEdit(prepayment.current as PrepaymentDto & {id:number});
      } else {
        openToAdd();
      }
      closePrepaymentDialog()
    }
  }, [isDialogOpened, prepayment]);


  return (
    <FormDialog<PrepaymentFormData>
      {...dialogProps}
      renderTitle={!itemToEdit ? 'Создание аванса': 'Редактирование аванса'}
      renderForm={(props) => {
        return <PrepaymentFormFields {...props} isNew={!itemToEdit} />;
      }}
    />
  );
};
