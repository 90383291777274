import { Type } from "class-transformer";
import { IsIn, IsNumber } from "class-validator";
import { ContractShortInfoDto } from "./contract.dto";
import { DownloadFileDto } from "./download-file.dto";

import { CAPPENDIX_STATUSES_KEYS, ContractAppendixStatus } from "./enums/contract-appendix-statuses";
import { InvoiceDto } from "./invoice.dto";

class Base {
  @Type(() => Date)
  date: Date;

  @IsNumber()
  invoiceId: number;

  @IsNumber()
  contractId: number;



  extraConditionsText: string;

  promoText: string;
  promoFileId: number | null;
}

export class CreateContractAppendixDto extends Base {
}

export class ContractAppendixDto extends Base {
  id: number;

  appendixNumber: number;

  @IsIn(CAPPENDIX_STATUSES_KEYS)
  status: ContractAppendixStatus;

  @Type(() => InvoiceDto)
  invoice: InvoiceDto;

  @Type(() => ContractShortInfoDto)
  contract: ContractShortInfoDto;

  @Type(() => DownloadFileDto)
  scanFile: DownloadFileDto | null;
}

export class UpdateContractAppendixDto {
  id: number;

  @Type(() => Date)
  date: Date;

  @IsIn(CAPPENDIX_STATUSES_KEYS)
  status: ContractAppendixStatus;

  scanFile?: File | null;

  extraConditionsText: string;

  promoText: string;
  // promoFileId: number | null;
}


export class InvoiceContractAppendixDto {
  invoiceId: number;
  appendix: ContractAppendixDto;
}
