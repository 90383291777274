import { MouseEvent } from 'react';
import MenuParams from 'shared/ui/menu-params';
import { IconButton, TableCell, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { InvoiceInfoDto, IPagingData } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { formatDate } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { InvoiceStatusSpan } from '../status-span/status-span';
import PriceSpan from 'shared/ui/price-span';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import ManagerSpan from 'shared/ui/manager-span';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { InvoiceFilter } from 'features/invoice/invoice-filter/invoice-filter';

const columns = ['Дата выставления счета', 'Номер', 'Период закрытия', 'Фирма', 'Наше юрлицо', 'Менеджер', 'Сумма', 'Прибыль', 'Статус', 'Акт', ''];

type Props = {
  collection: IRemotePagedCollection<InvoiceInfoDto>,
  paging: IPagingData,
  onChangePaging: (paging: IPagingData) => void,
  onDelete?: (invoice: InvoiceInfoDto) => void,
  onCancel?: (invoice: InvoiceInfoDto) => void,
};

export const SupplierInvoicesList = ({ collection, paging, onChangePaging, onDelete, onCancel }: Props) => {
  const { openMenu, menuData } = useMenu<InvoiceInfoDto>({ deleteHandler: onDelete, cancelHandler: onCancel });


  return (
    <>
      <InvoiceFilter />

      <GridLayout<InvoiceInfoDto>
        columns={columns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        noItemsText="Список счетов пуст"
        itemRender={(i) => <InvoiceRow
          key={i.id}
          invoice={i}
          onMenuClick={openMenu} />}
      />

      <RowMenu menuData={menuData} />
    </>);
};


type RowProps = {
  invoice: InvoiceInfoDto,
  onMenuClick: (menuParams: MenuParams<InvoiceInfoDto>) => void;
};

const InvoiceRow = (props: RowProps) => {
  const { invoice, onMenuClick } = props;

  const manager = invoice.manager ? formatPerson(invoice.manager) : '-';
  const priceTotal = invoice.totals.priceTotal;
  const discountPriceTotal = invoice.totals.discountPriceTotal;

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: invoice });
  };

  return (
    <>
      <TableRow
        key={invoice.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <LinkToInvoice invoice={invoice}>
            {formatDate(invoice.startDate)}
          </LinkToInvoice>
        </TableCell>
        <TableCell>
          <LinkToInvoice invoice={invoice} />
        </TableCell>
        <TableCell>{formatDate(invoice.startDate)}</TableCell>
        <TableCell>
          <LinkToCompany company={invoice.sellerCompany} /><br />
          <LinkToLegalEntity legalEntity={invoice.seller} />
        </TableCell>
        <TableCell><LinkToLegalEntity legalEntity={invoice.buyer} /></TableCell>
        <TableCell>
          <ManagerSpan person={invoice.manager} />
        </TableCell>
        <TableCell><PriceSpan size="small" price={priceTotal} /></TableCell>
        <TableCell></TableCell>
        <TableCell><InvoiceStatusSpan status={invoice.status} /></TableCell>
        <TableCell>-</TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};



