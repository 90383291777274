import { Button, Box } from '@mui/material';
import { useSnack } from 'shared/ui/snack';
import { useFormik } from 'formik';
import { FormData, LegalEntityBasicForm, validate, fromLegalEntity, toLegalEntity, emptyValues } from '../legal-entity-basic-form';
import { CardPaper } from 'shared/ui/card-paper';
import { useLegalEntity, useUpdateLegalEntity } from '../legal-entities.hook';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';


type Props = {
  legalEntityId: number;
};

export const LegalEntityBasicInfo = ({ legalEntityId }: Props) => {

  const { showError, showSuccess } = useSnack();

  const { legalEntity } = useLegalEntity(legalEntityId);
  const update = useUpdateLegalEntity();

  const handleSubmit = async (data: FormData) => {
    update(toLegalEntity(legalEntityId, data))
      .then((updatedLegalEntity) => {
        showSuccess('Данные о юрлице успешно обновлены');
      })
      .catch(e => showError(`Ошибка обновления данных о юрлице: ${extractNestJsErrorMessage(e)}`));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: legalEntity ? fromLegalEntity(legalEntity) : emptyValues,
    validate: validate,
    onSubmit: handleSubmit
  });

  return (
    <CardPaper >
      <Box mt={2}>
        <LegalEntityBasicForm formik={formik} />
      </Box>
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ mt: 2, ml: 'auto' }}
          variant="contained"
          autoFocus
          onClick={formik.submitForm}
          disabled={formik.isSubmitting || !formik.dirty}
        >
          {formik.isSubmitting ? 'Подождите...' : 'Сохранить'}
        </Button>
      </Box>
    </CardPaper>
  );
};
