import { Box, MenuItem, Stack } from '@mui/material';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { UserPickerField } from 'features/user/user-picker';
import { BankAccountPicker } from '../../../shared/ui/formik-fields/bank-account-picker';
import { SalaryPeriodPicker } from '../../salary/salary-period/salary-period-picker';
import { FormData } from '../prepayment.form-tools';
import { DatePickerField } from '../../../shared/ui/formik-fields/date-picker';
import { PayOrderStatus, POS_STATUSES } from '@sr/dto';

export const PrepaymentFormFields = (props: FormikProps<FormData> & { isNew: boolean }) => {

  const {isNew} = props

  return (
    <Box minWidth={400} pb={1}>
      <Stack spacing={2}>
        <SalaryPeriodPicker title="" />
        <Field
          required
          name="paymentDate"
          component={DatePickerField}
          fullWidth
          label="Дата ПП"
        />
        <Field
          fullWidth
          required
          name="userId"
          label="Сотрудник"
          component={UserPickerField}
        />
        <Field
          required
          name="description"
          component={TextField}
          fullWidth
          label="Назначение платежа"
        />
        <Field
          name='status'
          component={TextField}
          select
          fullWidth
          label='Статус'
          disabled={isNew}
        >
          {Object.keys(POS_STATUSES).map(key =>
            <MenuItem key={key} value={key}>
              {POS_STATUSES[Number(key) as PayOrderStatus]}
            </MenuItem>)}
        </Field>
        <Field
          required
          name="accountId"
          component={BankAccountPicker}
          label="Расчетный счет"
          fullWidth
        />
        <Field
          fullWidth
          required
          name="sum"
          label="Сумма внесения"
          type="number"
          component={TextField}
        />
      </Stack>
    </Box>
  );
};
