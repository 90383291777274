import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material';
import { LinkToFile } from 'shared/ui/links/link-to-file';
import * as React from 'react';
import { PayOrdersExportDto } from '@sr/dto';

export const PayOrdersExportReport=({ report }: { report: PayOrdersExportDto })=>{
  return <Card>
    <CardHeader
      title={`Экспорт для банка ${report.bankAccount}`}
      titleTypographyProps={{ variant: 'h6', noWrap: true }}
    />
    <CardContent>
      {`${report.count} платежек на сумму ${report.sum}. Платежки: ${report.payOrderIds.join(',')}`}
    </CardContent>
    <CardActions sx={{ pl: 2 }}>
      <LinkToFile file={report.file}></LinkToFile>
    </CardActions>
  </Card>;
};

