import { useEffect } from 'react';
import { useActionCreators } from 'shared/store';
import { Box, Stack, Typography } from '@mui/material';
import { InvoiceBasicForm } from 'features/invoice/invoice-mutable-data-form/invoice-basic-form';
import { useInvoiceDetails } from 'entities/invoice/invoice.hook';
import { InvoiceRowsCard } from 'entities/invoice/invoice-row/invoice-rows-card';
import { AgentCard } from 'entities/agent-card/agent-card';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { AVAILABLE_FOR_CHANGE_CANCEL_STATUSES, INVOICE_TYPE_CLIENT } from '@sr/dto';
import { useTitleHook } from 'hooks/page-title.hook';
import { invoiceLinkManager } from 'utils/link-manager';
import { InvoiceDocuments } from 'widgets/invoice/documents/invoice-documents';
import { PageHeader } from 'shared/ui/details-page-header';
import { InvoiceStatusSpan } from 'entities/invoice/status-span/status-span';
import { useInvoiceWcc } from 'features/invoice/invoice-wcc/lib';
import { useIdParam } from 'utils/url-paramters.hook';
import { InvoiceTitle } from 'features/invoice/title/title';
import { invoiceBasicsThunks } from 'entities/invoice/mutable-data/thunks';
import { ButtonLink } from 'shared/ui/link-base';
import { InvoicePrintFormDialog } from 'widgets/invoice/print-form/invoice-print-form-dialog';
import { PrintInvoiceButton } from 'widgets/invoice/print-form';
import { InvoiceCancelStatus } from 'features/invoice/invoice-cancel-status';

export const InvoiceDetailsPage = () => {
  const invoiceId = useIdParam('invoiceId');

  const { fetchInvoiceBasicsThunk } = useActionCreators(invoiceBasicsThunks);

  useEffect(() => {
    fetchInvoiceBasicsThunk(invoiceId);
  }, [fetchInvoiceBasicsThunk, invoiceId]);

  const { invoice, error } = useInvoiceDetails(invoiceId);

  useTitleHook(invoice && `Счет ${invoice.invoiceNumber} для ${invoice.buyerCompany.name}`);

  const { invoiceWcc } = useInvoiceWcc(invoiceId);

  const allowEditRows = !invoiceWcc;

  if (!invoice) {
    if (error)
      return <ErrorBanner errorMessage={error} />;

    return <LoadingBanner />;
  }

  return (
    <>
      <PageHeader
        title={<InvoiceTitle invoice={invoice} />}
        actions={
          <Stack direction="row" spacing={2}>
            <ButtonLink
              buttonProps={{ variant: 'contained' }}
              to={invoiceLinkManager.reportLink(invoice.id)}
            >
              Отчет
            </ButtonLink>
            <PrintInvoiceButton allowPrintWithStamp={invoice.type === INVOICE_TYPE_CLIENT} invoiceId={invoiceId} />
          </Stack>}
        subHeader={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="body1" component="span">Статус: </Typography>
              <InvoiceStatusSpan status={invoice.status} size="medium" />
            </Box>
            {AVAILABLE_FOR_CHANGE_CANCEL_STATUSES.includes(invoice.status) &&
              <InvoiceCancelStatus invoiceId={invoice.id} />}
          </Stack>
        }
      />

      <Stack direction="row" spacing={2} alignContent="flex-end" justifyItems="center">
        <Box>
          <InvoiceBasicForm allowEditEndDate={allowEditRows} invoice={invoice} />
        </Box>

        <Box>
          <Stack direction="column" spacing={2}>
            <AgentCard title="Покупатель" company={invoice.buyerCompany} legalEntity={invoice.buyer} />
            <AgentCard title="Продавец" company={invoice.sellerCompany} legalEntity={invoice.seller} />
          </Stack>
        </Box>

        <Box>
          <InvoiceDocuments invoiceId={invoice.id} />
        </Box>
      </Stack>

      <Box mt={2}>
        <InvoiceRowsCard
          invoiceId={invoice.id}
          allowEdit={allowEditRows}
          isClientInvoice={invoice.type === INVOICE_TYPE_CLIENT}
          seller={invoice.seller} />
      </Box>

      <InvoicePrintFormDialog invoiceId={invoiceId} />

    </>
  );
};

export default InvoiceDetailsPage;

