import { TableCell, TableRow } from "@mui/material"
import { InvoiceReportRowDto } from "@sr/dto"
import { LinkToInvoice } from "entities/client-invoices-list/ui/link-to-invoice"
import { GridLayout } from "shared/ui/grid-layout"
import { LinkToPurchase } from "shared/ui/links/link-to-purchase"
import { LinkToMedia } from "shared/ui/links/link-to-media"
import { formatDatesAndPeriod } from "utils/date-format-helpers"
import PriceSpan from "shared/ui/price-span"
import { ReactNode } from "react"

export type InvoiceReportTotals = {
  clientRowsTotal: number,
  supplierRowsTotal?: number,

  clientPartialPaidTotal?: number,
  supplierPartialPaidTotal?: number,

  totalProfitByPayments?: number,
  totalProfitByPurchases: number,
}

type Props = {
  rows: InvoiceReportRowDto[]
  totals: InvoiceReportTotals
}
export const InvoiceReportRows = ({ rows, totals }: Props) => {
  return (
    <>
      <GridLayout
        columns={['Заявка', 'Услуга', 'Количество', 'Период', 'Стоимость', 'Оплаты', 'Прибыль по платежкам', 'Прибыль по заявкам']}
        items={rows}
        isLoading={false}
        // error={purchases.error}
        itemRender={row => (<ReportRow key={row.clientRow.id} row={row} />)}
        noItemsText='Нет заявок'
        footer={() => <FooterRow totals={totals} />}
      />
    </>
  )
}

const ReportRow = ({ row }: { row: InvoiceReportRowDto; }) => {
  const linkedClientPurchase = row.purchase;
  const clientRow = row.clientRow;
  const supplierRow = linkedClientPurchase?.supplierInvoiceRow

  return (
    <TableRow>
      <TableCell>
        {linkedClientPurchase ? <LinkToPurchase purchase={linkedClientPurchase} /> : '-'}
      </TableCell>
      <TableCell>
        <CellLayout
          top={<>
            {clientRow.description}
            {clientRow.media && <LinkToMedia media={clientRow.media} />}
          </>}
          bottom={supplierRow
            ? <>
              {supplierRow.description}
              {linkedClientPurchase && <> (Счет: <LinkToInvoice invoice={linkedClientPurchase.supplierInvoice} />)</>}
            </>
            : '-'}
        />
      </TableCell>

      <TableCell>
        <CellLayout
          top={<>{clientRow.amount} шт</>}
          bottom={supplierRow?.amount ? supplierRow.amount + ' шт' : '-'} />
      </TableCell>
      <TableCell>
        <CellLayout
          top={formatDatesAndPeriod(clientRow.startDate, clientRow.endDate)}
          bottom={supplierRow ? formatDatesAndPeriod(supplierRow.startDate, supplierRow.endDate) : '-'} />
      </TableCell>

      <TableCell>
        <CellLayout
          top={<PriceSpan size='small' price={clientRow.priceDiscount} />}
          bottom={supplierRow ? <PriceSpan size='small' price={supplierRow.priceDiscount} /> : '-'}
        />
      </TableCell>

      <TableCell>
        <CellLayout
          top={clientRow.paidAmount && <PriceSpan size='small' price={clientRow.paidAmount} isApproximate />}
          bottom={
            supplierRow?.paidAmount
              ? <PriceSpan isApproximate size='small' price={supplierRow.paidAmount} />
              : "-"}
        />
      </TableCell>
      <TableCell>
        {row.profitByPayments && <PriceSpan isApproximate price={row.profitByPayments} zeroAsDash />}
      </TableCell>
      <TableCell>
        <PriceSpan price={row.profitByPurchases} zeroAsDash />
      </TableCell>
    </TableRow >);
};

const FooterRow = ({ totals }: { totals: InvoiceReportTotals }) => {
  return (
    <>
      <TableRow sx={{ borderBottom: "none" }}>
        <TableCell colSpan={4} align='right'>Итого доходы:</TableCell>
        <TableCell>
          <PriceSpan price={totals.clientRowsTotal} />
        </TableCell>
        <TableCell>
          <PriceSpan price={totals.clientPartialPaidTotal ?? 0} />
        </TableCell>
        <TableCell>
          <PriceSpan isApproximate price={totals.totalProfitByPayments ?? 0} zeroAsDash />
        </TableCell>
        <TableCell>
          <PriceSpan price={totals.totalProfitByPurchases} zeroAsDash />
        </TableCell>
      </TableRow>
      <TableRow >
        <TableCell colSpan={4} align='right'>Итого расходы:</TableCell>
        <TableCell>
          <PriceSpan price={totals.supplierRowsTotal ?? 0} />
        </TableCell>
        <TableCell>
          <PriceSpan price={totals.supplierPartialPaidTotal ?? 0} />
        </TableCell>
        <TableCell>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  )
}


const CellLayout = ({ top, bottom }: { top: ReactNode, bottom: ReactNode }) => (
  <>
    <div>{top}</div>
    <div>{bottom}</div>
  </>
)


