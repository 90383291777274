import { Stack, Typography } from '@mui/material';
import { formatPrice } from 'utils/price-format-helpers';
import PriceSpan from './price-span';

const PricePair = ({ price, priceDiscount, size }: { price: number, priceDiscount: number, size?: 'small' | 'regular' }) => {
  return (
    <Stack direction='column' spacing={0.5}>

      <div>
        {/* <Typography variant='body2' fontSize={10} component="span" >без скидки:</Typography> */}
        {price !== priceDiscount && <Typography
          variant='body2'
          color='grey.500'
          sx={{ textDecoration: 'line-through' }}>{formatPrice(price)}
        </Typography>}
      </div>
      <div>
        {/* <Typography variant='body2' fontSize={10} component="span">со скидкой:</Typography> */}
        {/* {formatPrice(priceDiscount)} */}
        <PriceSpan size={size} price={priceDiscount} />
      </div>
    </Stack>
  );
};

export default PricePair;
