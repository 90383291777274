import { Box, Stack } from '@mui/material';
import { CardPaper } from 'shared/ui/card-paper';
import { useOurCompany } from 'hooks/portal-settings.hook';
import {
  LegalEntityUrlParamsFilter,
} from 'entities/filters/legal-entity-url-params-filter';
import { BankAccountUrlParamsFilter } from 'entities/filters/bank-account-url-params-filter';

export const PayOrdersExportFilter = () => {
  const ourCompanyId = useOurCompany();

  return (
    <CardPaper sx={{ mb: 2 }}>
      <Stack direction="row" spacing={2} >
        <Box sx={{ flexBasis: 325, maxWidth: 400, flexGrow: 1 }}>
          <BankAccountUrlParamsFilter queryParamName={'bankAccountId'} />
        </Box>
        <Box sx={{ flexBasis: 300, flexGrow: 2 }}>
          <LegalEntityUrlParamsFilter queryParamName={'ourLegalEntityId'} companyIdFilter={ourCompanyId} label={'Наше юр. лицо'}/>
        </Box>
      </Stack>

    </CardPaper>
  );
};
