import {
  Divider,
  Stack,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { BankDetailsPicker } from 'shared/ui/formik-fields/bank-details-picker';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { SignerPicker } from 'shared/ui/formik-fields/signer-picker';
import { UserPickerField } from 'features/user/user-picker/ui';
import { ContractTemplatePickerField } from 'shared/ui/formik-fields//contract-template-picker-field';
import { FormValues } from 'components/Contracts/contract.form';
import { EntityRoles } from 'entities/invoice/types';
import { useBankDetailsDialog, useSignerDialog } from '../../../shared/providers/legal-entities.provider';
import { BankDetailsDialog } from '../../../components/Companies/BankDetails/bank-details-dialog';
import { SignerDialog } from '../../../components/Companies/Signers/signer-dialog';
import { ExternalPickerContainer } from '../../../components/Mui/external-picker-container';

type Props = {
  ourCompanyId?: number;
  buyerCompanyId?: number;
};

export const ContractFormFields = ({ ourCompanyId, buyerCompanyId }: Props) => {
  const { values } = useFormikContext<FormValues>();
  // наше юрлицо
  const sellerBankDetailsDialog = useBankDetailsDialog(values.sellerLegalEntityId);
  const sellerSignerDialog = useSignerDialog(values.sellerLegalEntityId);
  // юрлицо клиента
  const buyerBankDetailsDialog = useBankDetailsDialog(values.buyerLegalEntityId);
  const buyerSignerDialog = useSignerDialog(values.buyerLegalEntityId);

  return (
    <Stack direction='column' spacing={2}>
      <Field
        label='Название'
        fullWidth
        component={TextField}
        name='name' />
      <Stack direction='row' spacing={2}>
        <Field
          required
          label='Дата начала'
          component={DatePickerField}
          name='contractStartDate' />
        <Field
          required
          label='Дата окончания'
          component={DatePickerField}
          name='contractEndDate'
          minDate={values.contractStartDate}
        />
      </Stack>
      <Field
        required
        label='Тип договора'
        component={ContractTemplatePickerField}
        name='contractTemplateId' />
      <Field
        required
        label='Ответственный менеджер'
        component={UserPickerField}
        name='managerId'
        isLinkedToCompany
      />
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Stack direction="column" spacing={2} minWidth={400}>
          <Field
            required
            label='Наше юрлицо'
            fullWidth
            component={LegalEntityPicker}
            disableInactive={true}
            entityRole={EntityRoles.INVOICE_ISSUER}
            name='sellerLegalEntityId'
            companyIdFilter={ourCompanyId} />
          <Field
            required
            label='Наши банковские реквизиты'
            component={BankDetailsPicker}
            name='sellerBankDetailsId'
            legalEntityId={values.sellerLegalEntityId}
            collection={sellerBankDetailsDialog.collection} />
          <Field
            required
            label='Наш подписант'
            component={SignerPicker}
            legalEntityId={values.sellerLegalEntityId}
            collection = {sellerSignerDialog.collection}
            name='sellerSignerId' />
        </Stack>
        <Stack direction="column" spacing={2} minWidth={400} width = {'100%'}>
          <Field
            required
            label='Юрлицо клиента'
            component={LegalEntityPicker}
            disableInactive={true}
            name='buyerLegalEntityId'
            companyIdFilter={buyerCompanyId} />
          <ExternalPickerContainer disabled = {!values.buyerLegalEntityId}
            onClick={()=> { buyerBankDetailsDialog.setAddDialogOpen(true) }}>
            <Field
              required
              label='Банковские реквизиты клиента'
              component={BankDetailsPicker}
              name='buyerBankDetailsId'
              legalEntityId={values.buyerLegalEntityId}
              collection={buyerBankDetailsDialog.collection}/>
          </ExternalPickerContainer>
          <ExternalPickerContainer
            disabled = {!values.buyerLegalEntityId}
            onClick = {() => buyerSignerDialog.setOpenSignerDialog(true)} >
            <Field
              required
              label='Подписант клиента'
              component={SignerPicker}
              legalEntityId={values.buyerLegalEntityId}
              name='buyerSignerId'
              collection = {buyerSignerDialog.collection}
            />
          </ExternalPickerContainer>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction='column' sx={{ mt: 2 }}>
        <BankDetailsDialog
          open={buyerBankDetailsDialog.addDialogOpen}
          title='Добавить банковские реквизиты'
          onClose={() => buyerBankDetailsDialog.setAddDialogOpen(false)}
          submit={buyerBankDetailsDialog.handleAddBankDetails} />
        <SignerDialog
          onClose={()=> buyerSignerDialog.setOpenSignerDialog(false)}
          open={buyerSignerDialog.openSignerDialog}
          submit={buyerSignerDialog.handleAddSigner}
          title={'Добавить подписанта'}/>
      </Stack>
    </Stack>
  );
};
