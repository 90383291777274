import { IManagerLink, ICreateManagerLink, IDeleteManagerLink } from '@sr/dto';
import { authorizedRequest } from 'shared/api/axios';
import { COMPANY_BASE_URL } from 'shared/api/api';
import { omit } from '../../../utils/helper';

export type DeleteLinkResponse = {
  deletedLinkId: number;
};


export const fetchLinkedManagers = async (companyId: number) => {
  return authorizedRequest<Request, IManagerLink[]>(
    `${COMPANY_BASE_URL}/${companyId}/linkedManagers`,
    'GET'
  );
};

export const postLinkManager = async (link: ICreateManagerLink) => {
  return authorizedRequest<Omit<ICreateManagerLink, 'companyId'>, IManagerLink>(
    `/company/${link.companyId}/linkManager`,
    'POST',
    omit(link, ['companyId'])
  );
};

export const deleteLinkManager = async (dto: IDeleteManagerLink) => {
  return authorizedRequest<void, void>(
    `/company/unlinkManager/${dto.linkId}`,
    'DELETE'
  );
};
