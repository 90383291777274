import { ReactNode } from 'react';
import { IconButton, IconButtonProps, Stack } from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';

export const ExternalPickerContainer: React.FC<IconButtonProps & {children: ReactNode}> = (props) => {
  return (
    <Stack direction = "row"
      sx = {{ alignItems: 'center', justifyContent: 'space-between', width:'100%',
        '& > .MuiAutocomplete-root': { width: '100%' } }}>
      {props.children}
      <IconButton
        color="primary"
        aria-label="add"
        sx = {{ height: '32px', width: '32px' }}
        {...props}
      >
        <AddCircle />
      </IconButton>
    </Stack>
  );
};
