export class WccExportReportDto {
  exportedCount: number;
  files: {
    fileName: string;
    downloadPath: string;
  }[];
}




