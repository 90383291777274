import { plainToInstance, Type } from "class-transformer";
import {IsString, IsNumber, IsOptional, IsIn, IsBoolean, IsInt} from 'class-validator';
import { INVOICE_PRINT_NOT_SHOW_DATE, INVOICE_PRINT_SHOW_MONTH,INVOICE_PRINT_SHOW_ALL_DATE, InvoiceStatus } from '../main';
import { CompanyDto } from "./company.dto";
import { ContractInfoDto } from "./contract.dto";
import { INVOICE_TYPE_CLIENT, INVOICE_TYPE_SUPPLIER } from "./enums/invoice-types";
import { LegalEntityDto } from "./legal-entity.dto";
import { UserDto } from "./user.dto";

export type InvoiceTotals = {
  priceTotal: number;
  discountPriceTotal: number;
  partialPaidAmount: number;
  priceExclVatTotal: number;
  vatValueTotal: number;
}

export class InvoiceBasicResource {
  id: number;

  @Type()
  startDate: Date;

  @Type()
  endDate: Date;

  @IsNumber()
  managerId: number;

  @IsBoolean()
  printFormShowMediaLink: boolean;

  @IsNumber()
  @IsIn([INVOICE_PRINT_NOT_SHOW_DATE,INVOICE_PRINT_SHOW_MONTH,INVOICE_PRINT_SHOW_ALL_DATE])
  printFormShowDateViewState: number;

}

export class CreateInvoiceDto {
  @Type()
  startDate: Date;

  @Type()
  endDate: Date;

  invoiceNumber?: string;

  @IsNumber()
  sellerCompanyId: number;

  @IsNumber()
  sellerLegalEntityId: number;

  @IsOptional()
  @IsNumber()
  sellerSignerId: number;

  @IsOptional()
  @IsNumber()
  sellerBankDetailsId: number;

  @IsNumber()
  buyerCompanyId: number;

  @IsNumber()
  buyerLegalEntityId: number;

  @IsOptional()
  @IsNumber()
  contractId: number;

  @IsNumber()
  @IsIn([INVOICE_TYPE_CLIENT, INVOICE_TYPE_SUPPLIER])
  type: number;

  @IsNumber()
  managerId: number;
}

export type InvoiceInfo = {
  id: number;
  invoiceNumber: string;
  status: InvoiceStatus;
  manager: UserDto;

  sellerCompany: CompanyDto;
  seller: LegalEntityDto;

  buyerCompany: CompanyDto;
  buyer: LegalEntityDto;

  startDate: string;
  endDate: string;

  totals: InvoiceTotals;
}

export class InvoiceInfoDto {
  @IsNumber()
  id: number;

  invoiceNumber: string;

  @Type(() => Number)
  status: InvoiceStatus;

  @Type(() => UserDto)
  manager: UserDto;

  @Type(() => CompanyDto)
  sellerCompany: CompanyDto;

  @Type(() => LegalEntityDto)
  seller: LegalEntityDto;

  @Type(() => CompanyDto)
  buyerCompany: CompanyDto;
  @Type(() => LegalEntityDto)
  buyer: LegalEntityDto;


  @Type(() => Date)
  startDate: Date;

  @Type(() => Date)
  endDate: Date;

  totals: InvoiceTotals;
}

export class InvoiceDto extends CreateInvoiceDto {
  @IsNumber()
  id: number;

  invoiceNumber: string;

  @Type(() => Number)
  status: InvoiceStatus;

  @Type(() => UserDto)
  manager: UserDto;

  @Type(() => ContractInfoDto)
  contract: ContractInfoDto;

  @Type(() => CompanyDto)
  sellerCompany: CompanyDto;

  @Type(() => LegalEntityDto)
  seller: LegalEntityDto;

  @Type(() => CompanyDto)
  buyerCompany: CompanyDto;

  @Type(() => LegalEntityDto)
  buyer: LegalEntityDto;

  totals: InvoiceTotals;

  @IsBoolean()
  printFormShowMediaLink: boolean;

  @IsNumber()
  @IsIn([INVOICE_PRINT_NOT_SHOW_DATE,INVOICE_PRINT_SHOW_MONTH,INVOICE_PRINT_SHOW_ALL_DATE])
  printFormShowDateViewState: number;

  static fromPlain(obj: any) { return plainToInstance<InvoiceDto, any>(InvoiceDto, obj); }
}


export class UpdateInvoiceResource extends InvoiceBasicResource { }

export class InvoiceCanceledStatusDto {
  @IsBoolean()
  isCanceled: boolean;
}



