import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useCallback, useRef, useState } from 'react';
import { usePrintPopup } from 'utils/print-popup.hook';
import { useSnack } from 'shared/ui/snack';
import { fetchWccInvoicePrintForm, fetchWccPrintForm } from './api';

const options = ['Печать', 'Печать с подписью', 'Печать счета фактуры'] as const;
type PrintOption = typeof options[number]

type Props = {
  wccId: number
}

export const PrintWccButton = ({ wccId }:Props) => {
  const { showError } = useSnack();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [currentLabel, setCurrentLabel] = useState<PrintOption>('Печать');

  const { openWindow, loading } = usePrintPopup(
    useCallback(() => {
      return fetchWccPrintForm(wccId)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [wccId, showError])
  );

  const { openWindow: openWindowWithStamp, loading: loadingWithStamp } = usePrintPopup(
    useCallback(() => {
      return fetchWccPrintForm(wccId,true)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [wccId, showError])
  );

  const { openWindow: openWindowWccInvoice, loading: loadingWccInvoice } = usePrintPopup(
    useCallback(() => {
      return fetchWccInvoicePrintForm(wccId)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [wccId, showError])
  );

  const handleMenuItemClick = (
    label: PrintOption,
  ) => {
    setCurrentLabel(label);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };


  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
      >
        {currentLabel === 'Печать' && <Button disabled={loading} onClick={openWindow}>Печать</Button>}
        {currentLabel === 'Печать с подписью' && <Button disabled={loadingWithStamp} onClick={openWindowWithStamp}>Печать с подписью</Button>}
        {currentLabel === 'Печать счета фактуры' && <Button disabled={loadingWccInvoice} onClick={openWindowWccInvoice}>Печать счета фактуры</Button>}
        <Button
          disabled={loading || loadingWithStamp}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((label) => (
                    <MenuItem
                      key={label}
                      selected={currentLabel === label}
                      onClick={() => handleMenuItemClick(label)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
