import { UpdateUserRolesDto } from '@sr/dto';
import { FormTools }      from 'utils/form-tools';
import { FormikErrors } from 'formik';


export type FormValues = {
  roles: number[]
};


export const formTools: FormTools<UpdateUserRolesDto, void, UpdateUserRolesDto, FormValues> = {
  toCreateDto: formData => null,

  toUpdateDto: (id, formData): UpdateUserRolesDto => ({
    id: id,
    roles: formData.roles
  }),

  fromDto: (dto): FormValues => {
    return ({
      roles: dto.roles
    });
  },

  emptyValues: () => ({
    roles: []
  }),

  validate: (values: FormValues): FormikErrors<FormValues> => {
    let validationResult: FormikErrors<FormValues> = {};
    return validationResult;
  },
};
