import { fetchCollectionReq } from 'shared/api/axios';
import { IPagingData, MediaFilter, MediaInfoDto } from '@sr/dto';

export const fetchMediaDropdown = (paging: IPagingData,filterString: string = '') => {
  return fetchCollectionReq<MediaInfoDto>(
    `/media/dropdown?title=${filterString}`,
    MediaInfoDto,
    paging
  );
};
