import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { IManagerLink } from '@sr/dto';
import { LinkedManagersList } from 'entities/company/linked-managers/ui/linked-managers-list';
import { CardPaperTitled } from 'shared/ui/card-paper';
import { LinkManagerButton, AddManagerDialog } from 'features/company/link-manager/create';
import { LinkedManagerMoreMenu, useMore } from 'features/company/link-manager/linked-manager-more-menu';
import { useActionCreators } from 'shared/store';
import { linkedManagersThunks } from 'entities/company/linked-managers/linked-managers.slice';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { useLinkedManagers } from 'entities/company/linked-managers/model';

export const CompanyManagers = () => {
  const [open, setOpen] = useState(false);
  const { deleteLinkThunk } = useActionCreators(linkedManagersThunks);
  const showConfirm = useConfirmDialog();
  const { showSuccess, showError } = useSnack();

  const clickedLinkRef = useRef<IManagerLink>({} as IManagerLink);

  const { links } = useLinkedManagers();


  const { openMenu, menuData } = useMore({
    deleteHandler: () => {
      if (!clickedLinkRef.current)
        return;

      showConfirm({
        content: 'Удалить привязку менеджера?'
      }).then(() => deleteLinkThunk({
        linkId: clickedLinkRef.current.id,
      }).unwrap()
        .then(() => showSuccess('Связь с менеджером успешно удалена'))
        .catch((e: any) => showError(`Ошибка удаления связи с менеджером: ${e.message}`))
      );

    }
  });

  const handleMoreClick = (anchor: HTMLElement, link: IManagerLink) => {
    openMenu(anchor);
    clickedLinkRef.current = link;
  };


  return (
    <>
      <CardPaperTitled title="Менеджеры">

        <LinkedManagersList onMoreClick={handleMoreClick} />

        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ ml: 'auto' }}>
            <LinkManagerButton onClick={() => setOpen(true)} />
          </Box>
        </Box>
      </CardPaperTitled>

      <AddManagerDialog
        open={open}
        onClose={() => setOpen(false)}
        excludedManagers={links.items.map(x => x.manager.id)}
      />

      <LinkedManagerMoreMenu menuData={menuData} />
    </>
  );
};

export default CompanyManagers;
